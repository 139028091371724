import React from "react";
import Grid from "@material-ui/core/Grid";

import { HomeContentBox } from "components/Common";
import { PriceListAdmin, ReservationView } from "components/Admin";
import { connect } from "react-redux";
import { Calendar } from "components/pages/Reservations";

class AdministraceContent extends React.Component {
  render() {
    return (
      <Grid container>
        <HomeContentBox title="Správa ceníku" loading={this.props.loadingPrices} methodLoading={this.props.loadingPrice} xs={12} lg={7}>
          <PriceListAdmin  disableEdit={this.props.loadingPrice} />
        </HomeContentBox>
        <HomeContentBox xs={12} lg={5} noPadding loading={this.props.loadingCalendar} style={{ height: "inherit" }}>
          <Calendar readOnly withPending={true}/>
        </HomeContentBox>
        <HomeContentBox title="Správa rezervací" loading={this.props.loadingReservations} methodLoading={this.props.loadingReservation} xs={12}>
          <ReservationView />
        </HomeContentBox>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    periods: state.calendar.periods,
    loadingCalendar: state.calendar.loading,
    loadingPrice: state.prices.loadingItem,
    loadingPrices: state.prices.loading,
    loadingReservations: state.reservations.loading,
    token: state.authentication.token,
    userId: state.authentication.userId,
    loadingReservation: state.reservations.loadingItem,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdministraceContent);
