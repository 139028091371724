import Grid from "@material-ui/core/Grid";
import { HomeContentBox, VirtualView, Services } from "components/Common";
import { Aux } from "components/HOC";
import { withSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";

class HomeContent extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <Aux>
        <Grid container>
          <HomeContentBox level="0"
            title="Vítejte"
            xs={12}
            itemId="vitejte">
            <Services />
          </HomeContentBox>
          <VirtualView  xs={12} sm={12} md={6} />

            <HomeContentBox  level="1" title="Ubytování" okTitle="Více o ubytování..." xs={12} sm={12} md={6} itemId="ubytovani" extraClass="fix" okTitleSettings={{
              onClick: () => this.props.history.push("/ubytovani")
            }}
             />
            {/* <ReviewBox  level="1" xs={12} sm={12} md={6} title="Napsali o nás" /> */}
          <HomeContentBox  level="1" title="Kam vyrazit" xs={12} itemId="kam-vyrazit" okTitle="Tipy kam vyrazit..." okTitleSettings={{
              onClick: () => this.props.history.push("/kam-vyrazit")
            }}/>
          {/* <NewsBox xs={12} sm={6} md={4} title="Zimní sezóna v plném proudu" icon="landscape" color="red" subtitle="10. 11. 2018" backgroundImg="/img/news/bg.jpg" />
          <NewsBox xs={12} sm={6} md={4} title="Silvestrovská akce" subtitle="31. 12. 2018" icon="bar" color="blue" backgroundImg="/img/news/bg2.jpg" />
          <NewsBox xs={12} sm={6} md={4} title="Víkendová sleva" subtitle="1. 9. 2018" icon="hotel" color="green" backgroundImg="/img/news/bg3.jpg" /> */}
        </Grid>

        <div />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    periods: state.calendar.periods,
    loading: state.calendar.loading,
    token: state.authentication.token,
    userId: state.authentication.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomeContent)
);
