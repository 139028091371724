import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Aux, MessageWindow } from "components/HOC";
import { Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import * as Yup from "yup";
import {config} from "_constants"
import "./ReservationForm.css";

const styles = {};

const validationBase ={
  name: Yup.string()
    .min(4, "Zadejte jméno a příjmení")
    .max(255, "Jméno a příjmení jsou příliš dlouhé.")
    .required("Zadejte jméno a příjmení"),
  phone: Yup.string().required("Zadejte Vaše telefoní číslo"),
  email: Yup.string()
    .email("Zadejte platnou emailovou adresu")
    .required("Zadejte Vaši emailovou adresu")
}

const validationContact = {
  ...validationBase,
  note:  Yup.string().required("Vyplňete text Vašeho dotazu")
}

const validationReservation = {
  ...validationBase,
  dateFrom: Yup.date()
      .when("dateTo", (dateTo, schema) => dateTo && schema.max(dateTo, "Datum od musí být dříve než datum do"))
      .required("Zadejte datum od"),
    dateTo: Yup.date()
      .when("dateFrom", (dateFrom, schema) => dateFrom && schema.min(dateFrom, ""))
      .required("Zadejte datum do"),
    zipcode: Yup.number()
      .required("Zadejte PSČ"),
    street: Yup.string()
      .min(2, "Zadejte ulici a č.p.")
      .max(255, "Název ulice a č.p. je příliš dlouhý!")
      .required("Zadejte ulici a č.p."),
    city: Yup.string()
      .min(2, "Zadejte město")
      .required("Zadejte město"),
}


class ReservationForm extends React.Component {

  getValidationScheme(contact){
    return Yup.object().shape(
      contact? validationContact: validationReservation,
      ["dateFrom", "dateTo"]
    );
  }

  render() {
    let {classes, contactForm} = this.props;
    const defaultValues =  config.DEBUG ?
    { dateFrom: '2018-01-01', dateTo:'2018-01-05', name: "test joe", street: "abcd 14", city:"cityy", phone:"13456", email:"vad@dal.com", note: "aaa", zipcode: "12345" }:
    { dateFrom: "", dateTo: "", name: "", street: "", city: "", phone: "", email: "", note: "", zipcode: "" } ;
    return (
      <Aux>
        <Formik
          validationSchema={this.getValidationScheme(contactForm)}
          onSubmit={(values, actions) => {
            this.setState({ ...this.state, showDialog: true });
            if (contactForm)
              this.props.onInsertContact(values);
            else
              this.props.onInsertReservation(values);

          }}
          initialValues={defaultValues}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, isSubmitting, touched }) => (
            <form className="reservation-form" onSubmit={handleSubmit}>
              <Grid container spacing={8}>
                {!contactForm && 
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextField id="dateFrom" name="dateFrom" label="Datum od" type="date" className={classes.textField} InputLabelProps={{ shrink: true }} helperText={touched.dateFrom ? errors.dateFrom : ""} error={touched.dateFrom && Boolean(errors.dateFrom)} onChange={handleChange} onBlur={handleBlur} value={values.dateFrom} fullWidth />
                  </Grid>}
                {!contactForm && 
                  <Grid item xs={12} sm={6} lg={4}>
                    <TextField id="dateTo" name="dateTo" label="Datum do" type="date" className={classes.textField} InputLabelProps={{ shrink: true }} helperText={touched.dateTo ? errors.dateTo : ""} error={touched.dateTo && Boolean(errors.dateTo)} onChange={handleChange} onBlur={handleBlur} value={values.dateTo} fullWidth />
                  </Grid>}
                <Grid item xs={12} lg={12}>
                  <TextField id="name" name="name" label="Jméno a příjmení" helperText={touched.name ? errors.name : ""} error={touched.name && Boolean(errors.name)} onChange={handleChange} onBlur={handleBlur} value={values.name} fullWidth />
                </Grid>
                {!contactForm && 
                <Grid item xs={12} lg={12}>
                  <TextField id="street" name="street" label="Ulice a č.p." helperText={touched.street ? errors.street : ""} error={touched.street && Boolean(errors.street)} onChange={handleChange} onBlur={handleBlur} value={values.street} fullWidth />
                </Grid>}
                {!contactForm && 
                <Grid item xs={12} lg={12}>
                  <TextField id="city" name="city" label="Město" helperText={touched.city ? errors.city : ""} error={touched.city && Boolean(errors.city)} onChange={handleChange} onBlur={handleBlur} value={values.city} fullWidth />
                </Grid>}
                {!contactForm && <Grid item xs={12} lg={12}>
                  <TextField id="zipcode" name="zipcode" label="PSČ" type="number"  helperText={touched.zipcode ? errors.zipcode : ""} error={touched.zipcode && Boolean(errors.zipcode)} onChange={handleChange} onBlur={handleBlur} value={values.zipcode} fullWidth />
                </Grid>}
                <Grid item xs={12} lg={12}>
                  <TextField id="phone" name="phone" label="Telefon" type="phone" className={classes.textField} helperText={touched.phone ? errors.phone : ""} error={touched.phone && Boolean(errors.phone)} onChange={handleChange} onBlur={handleBlur} value={values.phone} fullWidth />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField id="email" name="email" label="Email" type="email" className={classes.textField} helperText={touched.email ? errors.email : ""} error={touched.email && Boolean(errors.email)} onChange={handleChange} onBlur={handleBlur} value={values.email} fullWidth />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField id="note" name="note" label={contactForm?"Váš dotaz": "Poznámka"}  className={classes.textField} helperText={touched.note ? errors.note : ""} error={touched.email && Boolean(errors.note)} onChange={handleChange} onBlur={handleBlur} value={values.note} fullWidth multiline />
                </Grid>
                <Grid item xs={12} className="buttons">
                  <Button variant="contained" color="primary" size="large" type="submit" disabled={this.props.processingReservation}>
                    {!contactForm? "Rezervovat" : "Odeslat"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <MessageWindow isOpen={!contactForm && this.props.reservationSuccess} submitted={this.props.onReservationReset} closed={this.props.onReservationReset} title="Děkujeme za vytvoření rezervace" okTitle="Zavřít">
          <div>Vaše rezervace byla zaznamenána, budeme se jí věnovat jakmile to bude možné. Rezervaci zkontrolujeme a budeme Vás kontaktovat s podrobnějšími informacemi.<br/> V případě jakýchkoliv dotazů nás neváhejte kontaktovat na telefoním čísle <strong>{this.props.contact.phone}</strong> nebo emailové adrese <strong><nobr><a href={"mailto://" + this.props.contact.email}>{this.props.contact.email}</a></nobr></strong>.<br/><br/> Těšíme se na Vaši návštěvu.</div>
        </MessageWindow>
        <MessageWindow isOpen={contactForm && this.props.contactSuccess} submitted={this.props.onReservationReset} closed={this.props.onReservationRese} title="Děkujeme za Váš dotaz" okTitle="Zavřít">
          <div>Vaš dotaz byl úspěšně odeslán. Budeme se mu věnovat ihned jakmile to bude možné.</div>
        </MessageWindow>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    prices: state.prices.prices,
    loading: state.prices.loading,
    dataArrived: state.prices.dataArrived,
    token: state.authentication.token,
    userId: state.authentication.userId,
    contact: state.contact,
    reservationSuccess: state.reservations.reservationSuccess,
    contactSuccess: state.reservations.contactSuccess,
    processingReservation: state.reservations.loadingItem,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPrices: () => dispatch(actions.getPrices()),
    onInsertReservation: (reservation) => dispatch(actions.insertReservation(reservation)),
    onReservationReset: () => dispatch(actions.resetReservation()),
    onInsertContact: (reservation) => dispatch(actions.insertContact(reservation)),
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReservationForm)
);
