import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "store";
import App from "./App";
import { history } from "_helpers";
import { Router } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import ScrollToTop from 'react-router-scroll-top'
import "assets/scss/material-kit-react.css?v=1.3.0";


ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
    <ScrollToTop>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
      </ScrollToTop>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
