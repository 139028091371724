
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { ReservationList } from "components/Admin";
import React from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import {  MessageWindow } from "components/HOC";

import "../../Common/Table.css";
import "./ReservationView.css";
import ReservationDialog from "./ReservationDialog";

class ReservationView extends React.Component {

  onNewClicked = () => this.setState({ ...this.state, showNewDialog: true });
  constructor(props){
    super(props);
    this.state={
      selectedTab: 0,
      showNewDialog: false,

    }
    this.onNewClicked.bind(this);
  }

  componentDidMount() {
    this.props.onFetchReservations();
  }
  render() {
    return (
      <div className="reservation-view">
      <Tabs color="primary" value={this.state.selectedTab} onChange={(event, newValue)=>this.setState({...this.state, selectedTab: newValue})}>
          <Tab label="Aktivní rezervace" />
          <Tab label="Dokončené rezervace" />
          <Tab label="Zrušené rezervace" />
        </Tabs>
          
      {this.state.selectedTab === 0 && <ReservationList disableEdit={this.props.loadingReservation} showConflicts={true}  reservations={this.props.reservations.filter(i=>!i.deleted &&  new Date(i.dateTo) >= new Date())}/>}
      {this.state.selectedTab === 1 && <ReservationList disableEdit={true} showConflicts={false} reservations={this.props.reservations.filter(i=>!i.deleted && new Date(i.dateTo) < new Date())}/>}
      {this.state.selectedTab === 2 && <ReservationList disableEdit={true} showConflicts={false} reservations={this.props.reservations.filter(i=>i.deleted)}/>}
      {/* <Button variant="contained" color="primary" className="submit-button" onClick={this.onNewClicked}>
              <NewIcon /> Nová
      </Button> */}
      <MessageWindow isOpen={this.state.showNewDialog} closed={() => this.setState({ ...this.state, showNewDialog: false })} title="Nová rezervace">
          <ReservationDialog onSubmit={this.onInsertPrice} closed={() => this.setState({ ...this.state, showNewDialog: false })} />
        </MessageWindow>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    reservations: state.reservations.items,
    loading: state.reservations.loading,
    dataArrived: state.reservations.dataArrived,
    token: state.authentication.token,
    userId: state.authentication.userId,
    loadingReservation: state.reservations.loadingItem,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchReservations: () => dispatch(actions.getReservations())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationView);
