import React from "react";

const Logo = (props) => {
   

    return (
        <svg version="1.1" id="Vrstva_2" x="0px" y="0px" 
        width="200px" height={props.height} viewBox="50.285 373.5 510.926 182" enableBackground="new 50.285 373.5 510.926 182"
        >
   <g>
       <path fill={props.mountainColor} d="M407.112,384.432l10.328-3.737l12.81,10.471c3.998,1.415,3.67,7.895,12.389,11.97
           c8.719,4.074,14.919,7.449,18.598,10.125c3.679,2.677,6.713,4.937,9.102,6.781c2.388,1.846,5.11,3.797,8.167,5.855
           c3.057,2.057,7.72,4.472,13.989,7.24c6.269,2.77-2.458,0.554,13.117,6.029c-4.44-1.305,5.423,3.896,13.098,8.686
           c2.185,5.844,8.317,10.165,11.181,14.817c1.929,5.052,27.726,15.065,31.321,21.493c-8.335,1.218-41.875-5.379-36.647-2.76
           l-10.508-5.711l-6.671,0.404c-26.961-3.021-29.095-3.115-35.277-2.281c-4.06-0.876,10.228-6.824,28.495-9.604
           c4.265-1.3,12.599-0.069,18.545-2.747c-2.83-4.242-6.74-10.588-11.288-12.594c-5.351-1.707,2.375,0.382-8.047-2.455
           c-10.422-2.838-8.722-2.586-12.051-3.787c-3.329-1.202-7.393-2.796-12.191-4.781s-9.419-4.02-13.86-6.1
           c-4.44-2.082-6.744-3.166-6.907-3.252l-0.245-0.131l-0.268,0.071c-0.179,0.048-3.157-0.432-8.936-1.437l-8.668-1.51l-2.369-2.014
           c-1.579-1.342-3.235-2.762-4.971-4.258c-1.734-1.497-3.818-2.965-6.253-4.406c-2.436-1.439-4.659-2.447-6.674-3.02l-3.021-0.859
           l0.352,5.979l0.353,5.98l5.005,1.701c3.337,1.135,7.528,2.368,12.576,3.699l6.63,3.456l3.531,0.888
           c1.728,1.563,3.065,2.742,4.015,3.533l1.424,1.188l4.258,1.412c2.839,0.941,5.834,2.037,8.982,3.286
           c3.15,1.25,4.788,2.086,4.912,2.511l0.188,0.635l-23.738-1.604c-15.826-1.07-25.59-2.091-29.292-3.063
           c-3.701-0.972-5.634-1.501-5.798-1.588l-0.244-0.13l1.772-1.845c1.181-1.229,2.845-2.13,4.99-2.702l3.22-0.859l-4.258-1.41
           c-2.839-0.941-6.354-2.028-10.547-3.262l-6.287-1.85l-1.775-2.662c-1.182-1.773-2.57-4.016-4.166-6.725l-2.393-4.064l-2.368,0.24
           c-1.578,0.16-3.348,1.219-5.307,3.178s-6.086,6.194-12.383,12.705c-6.296,6.513-12.1,10.998-17.41,13.459
           c-5.311,2.462-10.59,4.652-15.839,6.574c-5.248,1.922-10.153,3.884-14.718,5.885c-4.564,2-7.683,3.484-9.354,4.453
           c-1.672,0.968-3.3,2.316-4.886,4.045c-1.586,1.729-3.098,3.568-4.535,5.521c-1.438,1.95-2.675,2.9-3.709,2.85l-7.572,9.015
           l-0.383-3.377c1.28-6.425,5.757-9.133,7.171-12.384c1.414-3.251,3.585-6.312,6.517-9.183c2.93-2.871,5.41-4.838,7.439-5.901
           c2.029-1.064,5.326-2.596,9.89-4.597c4.564-2,8.577-3.724,12.037-5.169c3.459-1.444,5.959-2.829,7.497-4.154
           c1.54-1.325,3.503-4.068,5.889-8.231l3.578-6.243l-4.105-0.472c-2.738-0.316-5.44-0.184-8.107,0.398
           c-2.666,0.579-5.493,1.79-8.479,3.632c-2.984,1.841-8.737,6.64-17.257,14.397c-8.521,7.757-16.602,13.961-24.245,18.611
           c-7.642,4.65-12.657,7.555-15.044,8.713s-6.251,3.104-11.592,5.834c-5.342,2.73-11.352,5.248-18.031,7.551
           c-6.678,2.303-10.831,3.737-12.456,4.301c-1.625,0.564-3.153,1.037-4.584,1.418c-1.431,0.383-6.228,1.4-14.393,3.055
           c-8.165,1.656-18.538,2.92-31.12,3.793c-12.582,0.873-23.065,1.579-31.448,2.116c-8.383,0.537-14.063,0.941-17.042,1.214
           l-4.468,0.407l1.703-1.237c1.136-0.825,2.698-1.602,4.688-2.328c1.991-0.727,4.122-1.164,6.392-1.313
           c2.271-0.148,5.618-0.616,10.043-1.404c4.424-0.788,8.743-1.417,12.958-1.889c4.214-0.471,8.079-0.913,11.594-1.328
           c3.515-0.414,8.535-1.1,15.059-2.057c6.523-0.955,13.063-2.047,19.618-3.271c6.556-1.227,11.905-2.521,16.049-3.888
           c4.145-1.366,7.492-2.586,10.042-3.657c2.55-1.072,5.505-2.644,8.863-4.715c3.359-2.07,8.934-5.321,16.724-9.749
           c7.791-4.429,12.401-7.585,13.831-9.468l2.146-2.824l-1.75-0.611c-1.166-0.408-2.403-0.959-3.71-1.655
           c-1.306-0.697-4.491-2.395-9.555-5.093c-5.063-2.699-9.105-4.494-12.123-5.389l-4.526-1.34l-7.186-0.826l-9.204,0.887
           c-6.135,0.592-11.613,2.248-16.434,4.971c-4.82,2.722-8.637,5.764-11.45,9.126c-2.814,3.362-4.447,5.495-4.897,6.399
           c-0.451,0.904-1.259,1.902-2.425,2.998c-1.166,1.094-2.25,2.232-3.253,3.414c-1.002,1.182-1.849,1.767-2.542,1.755
           c-0.692-0.011-2.095,1.638-4.209,4.942c-2.114,3.307-3.793,5.094-5.037,5.359c-1.245,0.268-2.979,1.023-5.202,2.27l-3.335,1.869
           l1.048-4.59l1.049-4.59l-4.607,1.621c-3.072,1.08-6.956,2.441-11.651,4.086c-4.697,1.645-7.768,2.725-9.214,3.24
           c-1.446,0.517-4.608,0.869-9.484,1.06l-7.313,0.285l1.749-1.642c1.167-1.095,2.371-1.775,3.616-2.042
           c1.244-0.267,3.149-1.003,5.714-2.21c2.566-1.205,9.229-4.877,19.99-11.011s16.591-10.106,17.493-11.914
           c0.901-1.808,0.966-3.131,0.197-3.97l-1.156-1.26l-4.629,1.822c-3.087,1.215-7.594,3.462-13.518,6.739
           c-5.924,3.277-16.392,9.138-31.405,17.583c-15.014,8.443-24.071,13.341-27.174,14.69c-3.102,1.349-6.208,2.732-9.319,4.149
           c-3.109,1.418-5.726,2.311-7.849,2.682c-2.123,0.369-7.267,1.382-15.432,3.037c-8.165,1.654-13.036,2.561-14.615,2.721l-2.368,0.24
           l1.679-1.036c1.12-0.69,4.393-2.021,9.82-3.99c5.427-1.969,12.289-5.104,20.584-9.407c8.296-4.302,13.28-6.937,14.951-7.904
           c1.672-0.969,6.613-3.983,14.823-9.047c8.209-5.063,13.062-8.055,14.554-8.975c1.493-0.92,9.913-6.299,25.259-16.139
           c15.347-9.84,24.557-15.299,27.627-16.38l4.607-1.619l7.746,0.481l7.746,0.481l4.117-1.882c2.745-1.254,6.609-3.199,11.592-5.834
           c4.984-2.635,9.8-4.572,14.45-5.813c3.477-0.121,4.273-5.291,7.124,1.26l4.547,1.127l2.893,1.947
           c3.034,0.759,7.766-0.371,8.448,1.209c0.682,1.58,2.982,1.26,10.505,3.74c5.235,4.221,10.458,7.791,15.669,10.711
           c5.211,2.92,8.809,4.802,10.793,5.645l2.974,1.263l3.242-3.313c2.161-2.209,4.765-4.503,7.813-6.884
           c3.048-2.38,4.513-3.814,4.396-4.307l-0.175-0.736l1.342-0.357c0.894-0.239,2.631-1.029,5.213-2.37
           c2.581-1.341,5.407-2.553,8.479-3.633c3.071-1.079,7.438-2.114,13.098-3.101c5.661-0.987,10.354-1.847,14.079-2.578
           c3.725-0.732,7.228-1.797,10.509-3.195c3.281-1.396,5.979-2.998,8.093-4.803c2.115-1.804,3.623-2.859,4.524-3.165
           c0.902-0.306,3.604-1.19,8.106-2.651s7.647-2.432,9.436-2.908c1.789-0.477,4.09-0.895,6.905-1.254
           C400.668,391.992,401.948,384.111,407.112,384.432L407.112,384.432z"/>
   </g>
   <g id="Vrstva_1">
       
           <text transform="matrix(1.3 0 0 1 160 555)" fill={props.fill} fontFamily="'TitilliumWeb-Regular'" fontSize="60.1805">Ostružná</text>
       <text transform="matrix(1.3 0 0 1 320 496.5)" fill={props.fill} fontFamily="'TitilliumWeb-Regular'" fontSize="36">chalupa</text>
   </g>
   </svg>
    )
}

export default Logo