
const footerStyle = {

  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
 
  
};
export default footerStyle;
