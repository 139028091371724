import React from "react";
//import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Aux } from "components/HOC";
import { HomeContentBox } from "components/Common";
import { ReservationForm } from "components/pages/Reservations";

class KontaktContent extends React.Component {
  render() {
    return (
      <Grid container>
        
        <HomeContentBox level="0" extraClass="kontakt" title="Kde nás najdete" itemId="kontakt-kde" xs={12} lg={6}>
          <Aux>
              <div>
                  <div className="caption">Chalupa Ostružná</div>

                  <div>{this.props.contact.street}</div>
                  <div>{this.props.contact.zip}</div>
                  <div>
                    {this.props.contact.owner}, IČ: {this.props.contact.ic}
                  </div>
                  <div>Tel.: {this.props.contact.phone}</div>
                  <div>
                    Email: <a href={"mailto:" + this.props.contact.email}>{this.props.contact.email}</a>
                  </div>
                  </div>
            <div className="map">
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d2151.8195858096515!2d17.042699913371983!3d50.1899140142355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m3!3m2!1d50.1892566!2d17.0436683!4m0!5e0!3m2!1scs!2scz!4v1548056714699" width="100%" height="100%" frameborder="0" style={{ border: "0", minHeight: "400px" }} allowfullscreen />
            </div>
          </Aux>
        </HomeContentBox>
        <HomeContentBox level="0" title="Kontaktní formulář" itemId="kontakt" xs={12} lg={6}>
          <ReservationForm contactForm />
        </HomeContentBox>
      </Grid>
    );
  }
}
const mapStateToProps = state => {
  return {
    contact: state.contact
  };
};
export default connect(mapStateToProps)(KontaktContent);
