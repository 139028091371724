import { updateObject } from "_helpers/utility";
import { storeConstants } from "store";

const initialState = {
  periods: [],
  loading: false,
  dataArrived: false,
};
export function calendar(state = initialState, action) {
  switch (action.type) {
    case storeConstants.CALENDAR_GETALL_REQUEST:
      return updateObject(state, { loading: true, periods: [] });
    case storeConstants.CALENDAR_GETALL_SUCCESS:
      if (action.items){
        const invalid = action.items.filter(i => i.dateFrom > i.dateTo);
        for (const i of invalid)
          {
            const tmp = i.dateFrom;
            i.dateFrom = i.dateTo;
            i.dateTo = tmp;
          }
      }
      return updateObject(state, {
        loading: false,
        periods: action.items,
        dataArrived: true
      });
    case storeConstants.CALENDAR_GETALL_FAILURE:
      return updateObject(state, { loading: false, periods: [] });
    default:
      return state;
  }
}
