import React from "react";

const Skying = props => {
  return (
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 264.401 264.401"  width="1em" height="1em">
      <path fill={props.color}
        d="M212.398,43.725c-3.028-11.976,4.225-24.139,16.201-27.167c11.976-3.028,24.139,4.226,27.167,16.201
	c3.028,11.976-4.225,24.139-16.201,27.168C227.59,62.955,215.427,55.701,212.398,43.725z M158.357,205.074l9.656-10.679
	c10.455-11.563,15.124-27.036,12.811-42.451c-1.039-6.922-3.437-13.435-6.965-19.241l34.938-38.638
	c8.334-9.218,7.618-23.445-1.599-31.78c-1.81-1.636-3.818-2.911-5.932-3.854l-6.421-3.829
	c-20.291-12.101-44.819-12.862-65.391-3.021L69.481,9.009c-3.716-2.637-8.865-1.763-11.503,1.952l-0.587,0.827
	c-2.637,3.715-1.763,8.865,1.952,11.503l10.415,7.393c-7.258-0.205-14.517,1.317-21.156,4.499L9.167,7.191
	C6.552,5.335,2.928,5.95,1.072,8.565l0,0c-1.856,2.615-1.241,6.239,1.374,8.095l35.759,25.383
	c-3.979,3.635-4.377,9.793-0.835,13.902c1.979,2.295,4.771,3.472,7.58,3.472c2.312,0,4.635-0.798,6.523-2.425
	c5.575-4.806,12.736-6.896,19.725-6.155L53.615,70.281c-5.557,6.145-5.079,15.631,1.065,21.187c1.098,0.993,2.303,1.79,3.573,2.399
	c0.063,0.04,0.121,0.085,0.185,0.124l4.026,2.401c3.38,2.015,5.652,5.402,6.236,9.294c0.584,3.891-0.594,7.797-3.233,10.715
	l-9.196,10.171c-1.098,1.215-1.834,2.627-2.231,4.108L8.044,112.543c-2.983-1.176-6.355,0.289-7.531,3.271s0.289,6.355,3.271,7.531
	l124.228,48.983c5.966,2.352,12.709-0.577,15.062-6.543l-70.25-27.699l7.479-8.271c6.673-7.381,9.653-17.258,8.176-27.098
	c-0.979-6.525-3.846-12.483-8.152-17.247l23.62-26.122c0.823-0.911,1.498-1.9,2.057-2.934l7.757,5.506
	c-5.98,5.45-6.582,14.696-1.266,20.864c2.967,3.443,7.155,5.208,11.369,5.208c3.468,0,6.953-1.196,9.785-3.638
	c8.876-7.649,20.411-10.774,31.497-9.114l-30.118,33.308c-4.895,5.415-6.644,12.553-5.405,19.215
	c0.154,1.073,0.426,2.125,0.806,3.14c1.192,3.505,3.258,6.766,6.198,9.425c2.809,2.539,6.084,4.223,9.515,5.097
	c2.639,3.072,4.395,6.85,5.013,10.969c0.975,6.493-0.992,13.01-5.395,17.879l-12.226,13.521c-1.65,1.825-2.755,3.947-3.351,6.17
	l-69.927-27.572c-4.239-1.671-9.03,0.41-10.701,4.649l-0.372,0.944c-1.671,4.239,0.41,9.03,4.649,10.701l188.794,74.441
	c8.478,3.343,18.06-0.82,21.402-9.298l0.372-0.944L158.357,205.074z M119.245,32.222c6.978-1.764,11.204-8.851,9.439-15.828
	c-1.764-6.978-8.851-11.204-15.828-9.439c-6.978,1.764-11.204,8.851-9.439,15.829C105.18,29.761,112.267,33.987,119.245,32.222z"
      />
    </svg>
  );
};

export default Skying;
