import axios from 'axios';
import { config } from '_constants';
import {storeConstants} from "store";
import { authHeader } from '_helpers';
import * as snackbar from "./snackbar.actions"
export const getPeriodsStart = (token = void 0) => {
    return {
        type: storeConstants.CALENDAR_GETALL_REQUEST,
        token: token
    };
};

export const getPeriodsFail = (error) => {
    snackbar.logError(`Nepodařilo se získat seznam rezervovaných termínů`,error);
    return {
        type: storeConstants.CALENDAR_GETALL_FAILURE,
        error: error.message? error.message : error.toString()
    };
};


export const getPeriodsSuccess = (items) => {
    return {
        type: storeConstants.CALENDAR_GETALL_SUCCESS,
        items: items
    };
};

export const getPeriods = (token) => {
    return dispatch => {
        dispatch(getPeriodsStart());
        let url = token ? `${config.API_URL}/Reservations/Admin` : `${config.API_URL}/Reservations`;

        axios.get(url,{ headers: authHeader() })
            .then(res => {
                 const fetchedData = [];
                for (let key in res.data) {
                    fetchedData.push({
                        ...res.data[key],
                        id: key
                    });
                }
                dispatch(getPeriodsSuccess(fetchedData));
            })
            .catch(err => {
                dispatch(getPeriodsFail(err));
            });
    }
}