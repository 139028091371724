import { createMuiTheme } from "@material-ui/core/styles";

import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

export const themeDialogButtons = createMuiTheme({
  palette: {
    primary: { main: green[500], contrastText: "#fff" },
    secondary: { main: red[500], contrastText: "#fff" }
  },
  typography: { useNextVariants: true }
});
