import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";


import "./VirtualView.css";
//import "../HomeContentBox/HomeContentBox.css";

export const VirtualView = props => {
  let classes = "box-container";
  if (props.noPadding) classes += " no-padding";
  return (
    <Grid
      item
      xs={props.xs}
      md={props.md}
      sm={props.sm}
      lg={props.lg}
      className="virtual-view">
      <Card className={classes} style={{ height: "100%" }}>
        <CardHeader title={<h2>Virtuální prohlídka</h2>}/>
        {props.loading ? <LinearProgress color="primary" /> : null}
        {props.title && <h2>{props.title}</h2>}
        <CardContent className="text-content">
        <iframe 
        title="Virtuální prohlídka"
        src="https://www.google.com/maps/embed?pb=!4v1657877700477!6m8!1m7!1sCAoSLEFGMVFpcE5XMVlTa2JuaE1oV25FNWNaaTFuWDBJbnBWX0R1NHVCOXppdG1Y!2m2!1d50.18937860006627!2d17.04329761515155!3f52.74!4f-3.3900000000000006!5f0.4000000000000002" 
        style={{border:0, width: "100%", minHeight:"60vh"}}
        allowfullscreen="" 
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade"></iframe>
        </CardContent>
        
      </Card>
    </Grid>
  );
};

VirtualView.defaultProps = {
  okTitleSettings: {
    variant: void 0,
    color: "primary"
  }
};

export default VirtualView;