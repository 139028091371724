import IconButton from "@material-ui/core/IconButton";
import PublicIcon from "@material-ui/icons/Public";
import { HomeContentBox } from "components/Common";
import { Aux } from "components/HOC";
import React from "react";
import "./PlaceInfo.css";



class PlaceInfo extends React.Component {
  render() {
    return (
      <HomeContentBox className="place-info" {...this.props}>
        <Aux>
          {this.props.children}
          <div className="footer">
          {this.props.url && 
            <IconButton  title="Otevřít url"onClick={() =>  window.open(this.props.url, '_blank')}>
              <PublicIcon className="icon" />
            </IconButton>}
            {this.props.distance && <div className="distance">{this.props.distance}</div> }
          </div>
        </Aux>
      </HomeContentBox>
    );
  }
}

export default PlaceInfo;
