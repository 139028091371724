import { updateObject } from "_helpers/utility";
import { storeConstants } from "store";

const initialState = {
  prices: [],
  loading: false,
  dataArrived: false,
  selectedItem: void 0,
  loadingItem: false
};

const sortPrices = (prices)=>{
  if (!prices ||prices.length === 0)
    return []
  const arr = prices.sort((a,b)=>new Date(a.dateFrom) - new Date(b.dateFrom))
  //kontrola konfliktů
  for(let i=0;i< arr.length-1;i++){
    const a = arr[i];
    const b = arr[i+1];
    if (a.dateTo >= b.dateFrom)
    {
      a.conflict = true;
      b.conflict = true;
    }else{
      a.conflict=false;
    }
  }
  return arr;
}

export function prices(state = initialState, action) {
  switch (action.type) {
    //getall
    case storeConstants.PRICES_GETALL_REQUEST:
      return updateObject(state, { loading: true, prices: [] });
    case storeConstants.PRICES_GETALL_SUCCESS:
      return updateObject(state, {
        loading: false,
        prices: sortPrices(action.items),
        dataArrived: true
      });
    case storeConstants.PRICES_GETALL_FAILURE:
      return updateObject(state, { loading: false, prices: [] });
    //get
    case storeConstants.PRICES_GET_REQUEST:
      return updateObject(state, { loadingItem: true, selectedItem: void 0 });
    case storeConstants.PRICES_GET_SUCCESS:
      return updateObject(state, {
        loadingItem: false,
        selectedItem: action.item,
        dataArrived: true
      });
    case storeConstants.PRICES_GET_FAILURE:
      return updateObject(state, { loadingItem: false, selectedItem: void 0 });
    //insert
    case storeConstants.PRICES_INSERT_REQUEST:
      return updateObject(state, { loadingItem: true, selectedItem: void 0 });
    case storeConstants.PRICES_INSERT_SUCCESS:
      return updateObject(state, {
        loadingItem: false,
        prices: sortPrices([...state.prices, action.item]),
        dataArrived: true
      });
    case storeConstants.PRICES_INSERT_FAILURE:
      return updateObject(state, { loadingItem: false, selectedItem: void 0 });
    //update
      case storeConstants.PRICES_UPDATE_REQUEST:
      return updateObject(state, { loadingItem: true, selectedItem: void 0 });
    case storeConstants.PRICES_UPDATE_SUCCESS:
      return updateObject(state, {
        loadingItem: false,
        prices:  sortPrices([...state.prices.filter(i=>i.id !== action.item.id), action.item]),
        dataArrived: true
      });
    case storeConstants.PRICES_UPDATE_FAILURE:
      return updateObject(state, { loadingItem: false, selectedItem: void 0 });
    //delete
    case storeConstants.PRICES_DELETE_REQUEST:
      return updateObject(state, { loadingItem: true, selectedItem: void 0 });
    case storeConstants.PRICES_DELETE_SUCCESS:
      return updateObject(state, {
        loadingItem: false,
        prices: sortPrices([...state.prices.filter(i=>i.id !== action.id)]),
        dataArrived: true
      });
    case storeConstants.PRICES_DELETE_FAILURE:
      return updateObject(state, { loadingItem: false, selectedItem: void 0 });
    default:
      return state;
  }
}
