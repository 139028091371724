import axios from "axios";
import { storeConstants } from "store";
import * as actions from "store/actions";
import { config } from "_constants";
import { authHeader } from "_helpers";

//#region  GET
export const getPriceStart = (token = void 0) => {
  return {
    type: storeConstants.PRICES_GET_REQUEST,
    token: token
  };
};

export const getPriceFail = error => {
  return {
    type: storeConstants.PRICES_GET_FAILURE,
    error: error.message ? error.message : error.toString()
  };
};

export const getPriceSuccess = item => {
  return {
    type: storeConstants.PRICES_GET_SUCCESS,
    item: item
  };
};

export const getPrice = id => {
  if (!id) return dispatch => dispatch(getPriceSuccess(void 0));
  return dispatch => {
    dispatch(getPriceStart());
    let url = `${config.API_URL}/Prices/${id}`;

    axios
      .get(url, { headers: authHeader() })
      .then(res => {
        dispatch(getPriceSuccess(res.data));
      })
      .catch(err => {
        dispatch(getPriceFail(err));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se načíst položku ceníku\n${err}`, options: { variant: "error" } }));
      });
  };
};
export const getPricesStart = (token = void 0) => {
  return {
    type: storeConstants.PRICES_GETALL_REQUEST,
    token: token
  };
};

export const getPricesFail = error => {
  return {
    type: storeConstants.PRICES_GETALL_FAILURE,
    error: error.message ? error.message : error.toString()
  };
};

export const getPricesSuccess = items => {
  return {
    type: storeConstants.PRICES_GETALL_SUCCESS,
    items: items
  };
};

export const getPrices = () => {
  return dispatch => {
    dispatch(getPricesStart());
    let url = `${config.API_URL}/Prices`;

    axios
      .get(url, { headers: authHeader() })
      .then(res => {
        const fetchedData = [];
        for (let key in res.data) {
          fetchedData.push({
            ...res.data[key]
          });
        }
        dispatch(getPricesSuccess(fetchedData));
      })
      .catch(err => {
        dispatch(getPricesFail(err));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se načíst položky ceníku\n${err}`, options: { variant: "error" } }));
      });
  };
};
//#endregion GET

//#region  POST
export const insertPriceSuccess = price => {
  return {
    type: storeConstants.PRICES_INSERT_SUCCESS,
    item: price
  };
};

export const insertPriceFail = error => {
  return {
    type: storeConstants.PRICES_INSERT_FAILURE,
    error: error
  };
};

export const insertPriceStart = () => {
  return {
    type: storeConstants.PRICES_INSERT_REQUEST
  };
};

export const insertPrice = price => {
  return dispatch => {
    dispatch(insertPriceStart());
    let url = `${config.API_URL}/Prices`;
    axios
      .post(url, price, { headers: authHeader() })
      .then(response => {
        dispatch(insertPriceSuccess(response.data));
        dispatch(actions.enqueueSnackbar({ message: "Položka ceníku byla úspěšně přidána.", options: { variant: "success" } }));
      })
      .catch(error => {
        dispatch(insertPriceFail(error));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se přidat položku ceníku\n${error}`, options: { variant: "error" } }));
      });
  };
};
//#endregion POST

//#region  PUT
export const updatePriceSuccess = price => {
  return {
    type: storeConstants.PRICES_UPDATE_SUCCESS,
    item: price
  };
};

export const updatePriceFail = error => {
  return {
    type: storeConstants.PRICES_UPDATE_FAILURE,
    error: error
  };
};

export const updatePriceStart = () => {
  return {
    type: storeConstants.PRICES_UPDATE_REQUEST
  };
};

export const updatePrice = price => {
  return dispatch => {
    dispatch(updatePriceStart());
    let url = `${config.API_URL}/Prices`;
    axios
      .put(url, price, { headers: authHeader() })
      .then(response => {
        dispatch(updatePriceSuccess(price));
        dispatch(actions.enqueueSnackbar({ message: "Položka ceníku byla úspěšně aktualizována.", options: { variant: "success" } }));
      })
      .catch(error => {
        dispatch(updatePriceFail(error));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se aktualizovat položku ceníku\n${error}`, options: { variant: "error" } }));
      });
  };
};
//#endregion PUT

//#region  DELETE
export const deletePriceSuccess = id => {
  return {
    type: storeConstants.PRICES_DELETE_SUCCESS,
    id: id
  };
};

export const deletePriceFail = error => {
  return {
    type: storeConstants.PRICES_DELETE_FAILURE,
    error: error
  };
};

export const deletePriceStart = () => {
  return {
    type: storeConstants.PRICES_DELETE_REQUEST
  };
};

export const deletePrice = id => {
  if (!id || id < 0) {
    return dispatch => {
      dispatch(deletePriceFail("Záznam pro smazání nebyl nalezen podle ID"));
      dispatch(actions.enqueueSnackbar({ message: "Nepodařilo se aktualizovat položku ceníku.", options: { variant: "error" } }));
    };
  }

  return dispatch => {
    dispatch(deletePriceStart());
    let url = `${config.API_URL}/Prices/${id}`;
    axios
      .delete(url, { headers: authHeader() })
      .then(response => {
        dispatch(deletePriceSuccess(id));
        dispatch(actions.enqueueSnackbar({ message: "Položka ceníku byla úspěšně smazána.", options: { variant: "success" } }));
      })
      .catch(error => {
        dispatch(deletePriceFail(error));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se smazat položku ceníku\n${error}`, options: { variant: "error" } }));
      });
  };
};
//#endregion DELETE
