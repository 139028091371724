import { updateObject } from "_helpers/utility";
import { storeConstants } from "store";

const initialState = {
  items: [],
  loading: false,
  dataArrived: false,
  failure: false,
  success: false
};
export function content(state = initialState, action) {
  switch (action.type) {
    case storeConstants.CONTENT_GETALL_REQUEST:
      return updateObject(state, { loading: true, items: [] });
    case storeConstants.CONTENT_GETALL_SUCCESS:
      return updateObject(state, {
        loading: false,
        items: action.items,
        dataArrived: true
      });
    case storeConstants.CONTENT_GETALL_FAILURE:
      return updateObject(state, { loading: false, items: [] });
    case storeConstants.UPDATE_CONTENT_FAILURE:
      return updateObject(state, {
        loading: false,
        failure: true,
        success: false
      });
    case storeConstants.UPDATE_CONTENT_START:
      return updateObject(state, {
        loading: true,
        failure: false,
        success: false
      });
    case storeConstants.UPDATE_CONTENT_SUCCESS:
      const newItems = [...state.items];
      newItems[newItems.findIndex(i=>i.id === action.id)].text = action.text;
      return updateObject(state, {
        loading: false,
        items: newItems,
        failure: false,
        success: true
      });
    default:
      return state;
  }
}
