import { updateObject } from "_helpers/utility";
import { storeConstants } from "store";

const initialState = {
  items: [],
  loading: false,
  dataArrived: false,
};
export function reviews(state = initialState, action) {
  switch (action.type) {
    case storeConstants.REVIEWS_GETALL_REQUEST:
      return updateObject(state, { loading: true, items: [] });
    case storeConstants.REVIEWS_GETALL_SUCCESS:
      return updateObject(state, {
        loading: false,
        items: action.items,
        dataArrived: true
      });
    case storeConstants.REVIEWS_GETALL_FAILURE:
      return updateObject(state, { loading: false, items: [] });
    default:
      return state;
  }
}
