import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AccountCircle from "@material-ui/icons/AccountCircle";
import headerLinksStyle from "./headerLinksStyle.jsx";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import "./HeaderLinks.css";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/">
          Úvod
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/rezervace">
          Ceník / Rezervace
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/ubytovani">
          Ubytování
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/fotogalerie">
          Fotogalerie
        </NavLink>
      </ListItem>
      {props.isAuthenticated && (
        <ListItem className={classes.listItem}>
          <NavLink className={classes.navLink} to="/administrace">
            Administrace
          </NavLink>
        </ListItem>
      )}
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/kam-vyrazit">
          Kam vyrazit
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/kontakt">
          Kontakt
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/prihlaseni">
          <AccountCircle fontSize="small" />
        </NavLink>
      </ListItem>
    </List>
  );
}
const mapStateToProps = state => {
  return {
    isAuthenticated: !!state.authentication.token
  };
};

export default connect(mapStateToProps)(
  withStyles(headerLinksStyle)(HeaderLinks)
);
