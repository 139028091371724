import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Aux } from "components/HOC";
import { Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import * as Yup from "yup";
import { Button } from "@material-ui/core";
import { themeDialogButtons } from "_helpers";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {config} from "_constants"

//import "./PriceDialog.css";

const styles = {};




class PriceDialog extends React.Component {

  onDialogClosed = () => this.setState({ ...this.state, showDialog: false });

  constructor(props) {
    super(props);
    this.state = {
      showDialog: false
    };
    this.onDialogClosed.bind(this);
  }
  state = {};

  getValidationScheme(){
    return Yup.object().shape(
      {
        price: Yup.number()
          .required("Zadejte cenu ubytování").typeError("Cena má neplatnou hodnotu. Zadejte číselnou hodnotu."),
        dateFrom: Yup.date()
            .when("dateTo", (dateTo, schema) => dateTo && schema.max(dateTo, "Datum od musí být dříve než datum do"))
            .required("Zadejte datum od"),
          dateTo: Yup.date()
            .when("dateFrom", (dateFrom, schema) => dateFrom && schema.min(dateFrom, ""))
            .required("Zadejte datum do"),
        note:  Yup.string().nullable(),

      },
      ["dateFrom", "dateTo"]
    );
  }

  getDefaultValues() {
    if (!this.props.item) {
        return config.DEBUG ? {id:0, dateFrom: "2019-01-27", dateTo: "2019-01-28", price: "12222", note: "" } :
        {id:0, dateFrom: "", dateTo: "", price: "", note: "" }
    }
    else {
      return {
        ...this.props.item,
        dateFrom: this.props.item.dateFrom.substring(0, 10),
        dateTo: this.props.item.dateTo.substring(0, 10),
      };
    }
  }
  render() {
    let { classes} = this.props;
    
    return (
      <Aux>
        <Formik
          validationSchema={this.getValidationScheme()}
          onSubmit={this.props.onSubmit}
          initialValues={this.getDefaultValues()}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, isSubmitting, touched }) => (
            <form className="reservation-form" onSubmit={handleSubmit}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} lg={6}>
                <TextField id="dateFrom" name="dateFrom" label="Datum od" type="date" className={classes.textField} InputLabelProps={{ shrink: true }} helperText={touched.dateFrom ? errors.dateFrom : ""} error={touched.dateFrom && Boolean(errors.dateFrom)} onChange={handleChange} onBlur={handleBlur} value={values.dateFrom} fullWidth />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <TextField id="dateTo" name="dateTo" label="Datum do" type="date" className={classes.textField} InputLabelProps={{ shrink: true }} helperText={touched.dateTo ? errors.dateTo : ""} error={touched.dateTo && Boolean(errors.dateTo)} onChange={handleChange} onBlur={handleBlur} value={values.dateTo} fullWidth />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField id="price" name="price" label="Cena" helperText={touched.price ? errors.price : ""} error={touched.price && Boolean(errors.price)} onChange={handleChange} onBlur={handleBlur} value={values.price} fullWidth />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField id="note" name="note" label="Poznámka" className={classes.textField} helperText={touched.note ? errors.note : ""} error={touched.email && Boolean(errors.note)} onChange={handleChange} onBlur={handleBlur} value={values.note} fullWidth multiline />
              </Grid>
            </Grid>
            <Grid item xs={12} className="buttons">
              <MuiThemeProvider theme={themeDialogButtons}>
                <Button variant="contained" color="primary" type="submit" disabled={this.props.loading}>
                  Uložit
                </Button>
                <Button variant="contained" color="secondary"  type="button" disabled={this.props.loading} onClick={this.props.closed}>
                  Zavřít
                </Button>
              </MuiThemeProvider>
            </Grid>
          </form>
          )}
        </Formik>

      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    prices: state.prices.prices,
    loading: state.prices.loading,
    dataArrived: state.prices.dataArrived,
    token: state.authentication.token,
    userId: state.authentication.userId,
    contact: state.contact
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPrices: () => dispatch(actions.getPrices())
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PriceDialog)
);
