import React from "react";
import PropTypes from "prop-types";
import * as Scroll from "react-scroll";
import { NavLink } from "react-router-dom";
import { List, ListItem, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

import "./Footer.css";
import footerStyle from "./footerStyle.jsx";

function Footer({ ...props }) {
  const { classes } = props;

  return (
    <footer className={classes.footer}>
      <Grid container className="about p-rel">
        <div className="top-outer hidden-old">
          <div className="top-inner" onClick={() => Scroll.animateScroll.scrollToTop()}>
            <div className="icon chevron">keyboard_arrow_up</div>
          </div>
        </div>
        <Grid item xs={12} sm={5}>
          <div>
            <div className="title">Rychlý kontakt:</div>
            <ul>
            <li>
                Chalupa Ostružná
              </li>
              
              <li>
                {props.contact.street}
              </li>
              <li>
                {props.contact.zip}
              </li>
              <li>
                {props.contact.owner}, IČ: {props.contact.ic}
              </li>
              <li>Tel.: {props.contact.phone}</li>
              <li>
                Email: <a href={"mailto:" + props.contact.email}>{props.contact.email}</a>
              </li>
            </ul>
            <p>V případě zájmu nás neváhejte kontaktovat. Těšíme se na Vaši návštěvu.</p>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} className="quicklinks">
          <div className="title">Odkazy</div>
          <div className="entry">
            <List onClick={() => Scroll.animateScroll.scrollToTop()}>
              <ListItem className={classes.listItem}>
                <NavLink className={classes.navLink} to="/">
                  Úvod
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/rezervace">Ceník / Rezervace</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/fotogalerie">Fotogalerie</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/ubytovani">Ubytování</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/kam-vyrazit">Kam vyrazit</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/kontakt">Kontakt</NavLink>
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
      <div className="copyright">
        <div className="author">
          © 2019 <a href="mailto:pavlos.web@centrum.cz">Pavel Bednář</a> &amp; Chalupa ostružná, všechna práva vyhrazena.
        </div>
        <div className="credits">
          <div>Icons made by <a href="https://www.freepik.com/" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" 			    title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" 			    title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a>,&nbsp;</div>
          <div>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" 			    title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" 			    title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool
};
const mapStateToProps = state => {
  return {
    contact: state.contact
  };
};
export default withStyles(footerStyle)(connect(mapStateToProps)(Footer));
