import React from 'react';
import { MainPage } from "components/pages";
import { Route, Switch, withRouter } from "react-router-dom"
import { AsyncComponent } from "components/HOC";
import { connect } from 'react-redux';
import * as actions from "store/actions";

import './App.css';


const asyncLogin = AsyncComponent(() => import("components/Layout/LoginPage/LoginPage"));

class App extends React.Component {

  componentDidMount() {
    this.props.onTryAutoSignup();
  }
  render() {
    return (
      <div>
          <Switch>
            <Route path="/prihlaseni" component={asyncLogin} />
            <Route path="/" component={MainPage} />
          </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(App)) ;
//const connectedApp =  withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
//export { connectedApp as App };