import React from "react";
import "./Content.css"
const Content = (props) => {
    return (
        <main className="main main-raised">
            {/* <div className="container"> */}
            <div>
                {props.children}
            </div>
        </main>
    )
}

export default Content