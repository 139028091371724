export { default as HomeContentBox } from "./HomeContentBox/HomeContentBox";
export { default as ContentLoader } from "./ContentLoader/ContentLoader";
export { default as NewsBox } from "./NewsBox/NewsBox";
export { default as Review } from "./ReviewBox/Review";
export { default as ReviewBox } from "./ReviewBox/ReviewBox";
export { default as ReviewCarousel } from "./ReviewBox/ReviewCarousel";
export { default as SubmitButton } from "./SubmitButton/SubmitButton";
export { default as ContentBox } from "./ContentBox/ContentBox";
export { default as Services } from "./Services/Services";
export { default as PlaceInfo } from "./PlaceInfo/PlaceInfo";
export { default as VirtualView } from "./VirtualView/VirtualView";
