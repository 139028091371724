import { updateObject } from '_helpers/utility';
import { storeConstants } from 'store';

let userId = localStorage.getItem('userId');
const initialState = {
  loggedIn: true,
  loggingIn: false,
  userId: userId ? userId : void 0,

}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case storeConstants.LOGIN_REQUEST:
      return updateObject(state, { loggingIn: true });
    case storeConstants.LOGIN_SUCCESS:
      return updateObject(state, { loggingIn: false, loggedIn: true, userId: action.userId, token: action.token });
    case storeConstants.LOGIN_FAILURE:
      return updateObject(state, { loggingIn: false, loggedIn: false, error: action.error });
    case storeConstants.CLEAR_MESSAGES:
      return updateObject(state, { error: void 0 });
    case storeConstants.LOGOUT:
      return updateObject(state, { loggingIn: false, loggedIn: false, userId: void 0 });
    default:
      return state
  }
}