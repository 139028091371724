import { container } from "components/Layout/material-kit-react.jsx";

const carouselStyle = {
  mainCarousel: {
  },
  section: {
    padding: "0px 0"
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  },
  carouselIcon:{
    backgroundColor:"red"
  }
};

export default carouselStyle;
