import React from "react";
import LazyLoad from "react-lazyload";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Landscape from "@material-ui/icons/Landscape";
import LocalBar from "@material-ui/icons/LocalBar";
import Hotel from "@material-ui/icons/Hotel";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import { Aux } from "components/HOC";
import { ContentLoader } from "components/Common";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import "./NewsBox.css";

const styles = theme => ({
  card: {
    maxWidth: 400
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8
    }
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {},
  red: {
    backgroundColor: red[500]
  },
  green: {
    backgroundColor: green[500]
  },
  blue: {
    backgroundColor: blue[500]
  }
});

class NewsBox extends React.Component {
  render() {
    const { classes } = this.props;
    let colorClasses = classes.red;
    if (this.props.color === "blue") colorClasses = classes.blue;
    else if (this.props.color === "green") colorClasses = classes.green;
    return (
      <Grid
        item
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.md}
        lg={this.props.lg}
        className="news-box">
        <Card className={"box-container"}>
          <CardHeader
            avatar={
              <Avatar
                aria-label="Recipe"
                className={[classes.avatar, colorClasses].join(" ")}>
                {this.props.icon === "landscape" && <Landscape />}
                {this.props.icon === "bar" && <LocalBar />}
                {this.props.icon === "hotel" && <Hotel />}
              </Avatar>
            }
            action={
              this.props.isAuthenticated ? (
                <Aux>
                  <IconButton color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton color="primary">
                    <DeleteIcon />
                  </IconButton>
                </Aux>
              ) : (
                void 0
              )
            }
            title={this.props.title}
            subheader={this.props.subtitle}
          />
          <LazyLoad height={200} once>
            <CardMedia
              className={classes.media}
              image={this.props.backgroundImg}
            />
          </LazyLoad>
          <CardContent>
            <Typography component="div">
              <ContentLoader />
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: !!state.authentication.token
  };
};

// const exp = connect(mapStateToProps)(
//   withStyles(styles)(NewsBox)
// );
// export {exp as NewsBox}
export default connect(mapStateToProps)(withStyles(styles)(NewsBox));
