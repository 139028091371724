import React from "react";
import * as actions from "store/actions";
import { AsyncComponent, Aux, Content, PrivateRoute } from "components/HOC";
import { Header, HeaderLinks, Footer } from "components/Layout/MainPage";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Notifier } from "components/UI";

const asyncAdministrace = AsyncComponent(() => import("components/pages/AdministraceContent"));
const asyncRezervace = AsyncComponent(() => import("components/pages/RezervaceContent"));
const asyncFotogalerie = AsyncComponent(() => import("components/pages/FotogalerieContent"));
const asyncUbytovani = AsyncComponent(() => import("components/pages/UbytovaniContent"));
const asyncKamVyrazit = AsyncComponent(() => import("components/pages/KamVyrazitContent"));
const asyncKontakt = AsyncComponent(() => import("components/pages/KontaktContent"));
const asyncHome = AsyncComponent(() => import("components/pages/HomeContent"));

class MainPage extends React.Component {
  componentDidMount() {
    this.props.onFetchContent();
  }
  render() {
    const dashboardRoutes = [];
    return (
      <Aux>
        <Header color="transparent" routes={dashboardRoutes} brand="Chata Ostružná" rightLinks={<HeaderLinks />} fixed changeColorOnScroll={{ height: 80, color: "white" }} logoColor1={"white"} logoColor2={"#333"} mountainColor1={"#AAA"} mountainColor2={"#AAA"} mountainColorHover={"#999"} />
        <Content>
          <Switch>
            <PrivateRoute exact path="/administrace" component={asyncAdministrace} />
            <Route path="/ubytovani" component={asyncUbytovani} />
            <Route path="/rezervace" component={asyncRezervace} />
            <Route path="/fotogalerie" component={asyncFotogalerie} />
            <Route path="/kam-vyrazit" component={asyncKamVyrazit} />
            <Route path="/kontakt" component={asyncKontakt} />
            <Route path="/" component={asyncHome} />
          </Switch>
        </Content>
        <Footer />
        <Notifier />
      </Aux>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchContent: () => dispatch(actions.getContent())
  };
};
export default connect(
  void 0,
  mapDispatchToProps
)(MainPage);
