import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import TrashIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { ContentLoader, SubmitButton } from "components/Common";
import { Aux } from "components/HOC";
import { ContentState, Editor, EditorState } from "draft-js"; //TODO: make async
import "draft-js/dist/Draft.css";
import React from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import "./HomeContentBox.css";



class HomeContentBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: props.content && props.content.text ? EditorState.createWithContent(ContentState.createFromText(props.content.text)) : EditorState.createEmpty(),
      isEditing: false
    };
    this.onChange = editorState => this.setState({ ...this.state, editorState });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.content && newProps.content.text && !newProps.contentLoading)
      this.setState({
        ...this.state,
        editorState: EditorState.createWithContent(ContentState.createFromText(newProps.content.text))
      });
  }
  render() {
    let classes = "box-container " + (this.props.noPadding ? "no-padding" : "");
    let myClass = this.props.className ? "home-content-box " + this.props.className : "home-content-box";
    if (this.props.extraClass)
    myClass += " " + this.props.extraClass;
    if (this.props.plain) myClass += " plain";
    return (
      <Grid item xs={this.props.xs} md={this.props.md} sm={this.props.sm} lg={this.props.lg} className={myClass}>
        {this.props.plain ? this.renderPlain(classes) : this.renderCard(classes)}
      </Grid>
    );
  }

  renderInner() {
    return (
      <Aux>
        {(this.props.loading || this.props.methodLoading) ? <LinearProgress color="primary" className="top-progress" /> : null}
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderActions()}
      </Aux>
    );
  }

  renderCard(classes) {
    return (
      <Card className={classes} style={this.props.styleObj}>
        {this.renderInner()}
      </Card>
    );
  }

  renderPlain(classes) {
    return (
      <div className={classes} style={this.props.styleObj}>
        {this.renderInner()}
      </div>
    );
  }

  renderHeader() {
    if (!this.props.title) return; //pokud není titulek ani autorizace, není hlavička potřeba
    if (this.props.level && this.props.level == 0) return <CardHeader className="level-0" title={this.props.title ? <h1>{this.props.title}</h1> : void 0} action={this.props.isAuthenticated ? this.renderHeaderActions() : null} />;
    if (this.props.level && this.props.level == 2) return <CardHeader className="level-2" title={this.props.title ? <h3>{this.props.title}</h3> : void 0} action={this.props.isAuthenticated ? this.renderHeaderActions() : null} />;
    else return <CardHeader title={this.props.title ? <h2>{this.props.title}</h2> : void 0} action={this.props.isAuthenticated ? this.renderHeaderActions() : null} />;
  }

  formatText(content) {
    if (!content) return null;
    const lines = content.split("\n");
    return lines.map((c, index) => (c.trim().startsWith("-") ? this.renderBullet(c,index) : this.renderLine(c,index)));
  }
  renderBullet(text, index) {
    const parts = text.split("-");

    return (
      <div key={"line" + index} className="line bullet">
        <i className="bullet"> <KeyboardArrowRight style={{fontSize:"0.9em"}}/> </i>
        <span>{parts[1].trim()}</span>
      </div>
    );
  }
  renderLine(text,index) {
    return (
      <div key={"line" + index} className="line">
        {text}
      </div>
    );
  }

  renderContent() {
    const textContent = this.props.contentLoaded && this.props.content ? <div className="text-content">{this.formatText(this.props.content.text)}</div> : this.props.content ? <ContentLoader /> : null;

    return (
      <CardContent className="card-content">
        <Aux>
          {this.state.isEditing && this.props.content ? (
            <div className="editor">
              <Editor editorState={this.state.editorState} onChange={this.onChange} />
            </div>
          ) : (
            textContent
          )}
          {this.props.children}
        </Aux>
      </CardContent>
    );
  }

  renderHeaderActions() {
    let showEditButton = this.props.isAuthenticated && this.props.itemId && this.props.contentLoaded;
    if (showEditButton && !this.state.isEditing)
      return (
        <IconButton color="primary"
          onClick={() => {
            this.setState({ ...this.state, isEditing: true });
          }}>
          <EditIcon />
        </IconButton>
      );

    if (this.props.isAuthenticated && this.state.isEditing)
      return (
        <CardActions disableActionSpacing>
          <SubmitButton loaging={this.props.contentLoading} action={async () => this.props.onUpdateContent(this.props.itemId, this.state.editorState.getCurrentContent().getPlainText())} onSuccess={() => this.setState({ ...this.state, isEditing: false })} />
          <IconButton aria-label="Zahodit" color="primary">
            <TrashIcon onClick={() => this.setState({ ...this.state, isEditing: false })} />
          </IconButton>
        </CardActions>
      );
    else return null;
  }

  renderActions() {
    if (!this.state.isEditing && this.props.okTitle)
      return (
        <Button color="primary"
          {...this.props.okTitleSettings}
          className={["button", this.props.okTitleSettings.cssClass ? this.props.okTitleSettings.cssClass : ""].join(" ")}
          style={{
            float: this.props.okTitleSettings.position ? this.props.okTitleSettings.position : "right"
          }}>
          {this.props.okTitle}
        </Button>
      );
  }
}

HomeContentBox.defaultProps = {
  okTitleSettings: {
    variant: void 0,
    color: "primary"
  }
};
const mapStateToProps = (state, props) => {
  return {
    content: (state.content.items || []).find(i => i.id === props.itemId),
    contentLoading: state.content.loading,
    contentLoaded: state.content.dataArrived,
    success: state.content.success,
    failure: state.content.failure,
    isAuthenticated: !!state.authentication.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateContent: (key, text) => dispatch(actions.updateContent(key, text))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContentBox);
