import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const loggerMiddleware = process.env.NODE_ENV === 'development' ? createLogger() : void 0;

export const store = process.env.NODE_ENV === 'development'  ?
    createStore(rootReducer, composeEnhancers(
        applyMiddleware(thunkMiddleware, loggerMiddleware)
    ))
    :
    createStore(rootReducer, composeEnhancers(
        applyMiddleware(thunkMiddleware)
    ));
