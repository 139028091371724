import React from "react";
//import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import { PlaceInfo, HomeContentBox } from "components/Common";
import { Equalizer, Aux } from "components/HOC";
class KamVyrazitContent extends React.Component {
  componentDidMount() {
  }
  render() {
    return (
      <Aux>
        <Grid container>
          <HomeContentBox xs={12} title="Kam vyrazit" level="0" itemId="kam-vyrazit" />
        </Grid>
        <Grid container>
            <PlaceInfo xs={12} sm={12} md={4} lg={4}  title="Ski areál Jonas Park" distance="50 m" itemId="kam-vyrazit-jonas" url="http://www.ostruzna.cz/skiareal"/>
            <PlaceInfo xs={12} sm={12} md={4} lg={4} title="Ski areál Ramzová" distance="1 km" itemId="kam-vyrazit-ramzova" url="http://www.bonera.cz/"/>
            <PlaceInfo xs={12} sm={12} md={4}  lg={4} title="Horské minikáry" distance="500 m" itemId="kam-vyrazit-minikary" url="http://www.haltmar.cz/642/horske-minikary/"/>
            <PlaceInfo xs={12} sm={12} md={4}  lg={4} title="Cykloturistické trasy" itemId="kam-vyrazit-cyklo" url="http://www.ostruzna.cz/turistika"/>
            <PlaceInfo xs={12} sm={12} md={4}  lg={4} title="Rozhledna Zlatý Chlum" distance="25 km" itemId="kam-vyrazit-chlum" />
            <PlaceInfo xs={12} sm={12} md={4}  lg={4} title="Termální lázně Velké Losiny" distance="25 km" itemId="kam-vyrazit-losiny" url="https://www.lazne-losiny.cz/" />
        </Grid>
      </Aux>
    );
  }
}

export default KamVyrazitContent;
