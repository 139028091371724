export const storeConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    SET_AUTH_REDIRECT_PATH : 'SET_AUTH_REDIRECT_PATH',
    CLEAR_MESSAGES: 'CLEAR_MESSAGES',

    CALENDAR_GETALL_REQUEST: 'CALENDAR_GETALL_REQUEST',
    CALENDAR_GETALL_SUCCESS: 'CALENDAR_GETALL_SUCCESS',
    CALENDAR_GETALL_FAILURE: 'CALENDAR_GETALL_FAILURE',

    PRICES_GETALL_REQUEST: 'PRICES_GETALL_REQUEST',
    PRICES_GETALL_SUCCESS: 'PRICES_GETALL_SUCCESS',
    PRICES_GETALL_FAILURE: 'PRICES_GETALL_FAILURE',

    PRICES_GET_REQUEST: 'PRICES_GET_REQUEST',
    PRICES_GET_SUCCESS: 'PRICES_GET_SUCCESS',
    PRICES_GET_FAILURE: 'PRICES_GET_FAILURE',

    PRICES_INSERT_REQUEST: 'PRICES_INSERT_REQUEST',
    PRICES_INSERT_SUCCESS: 'PRICES_INSERT_SUCCESS',
    PRICES_INSERT_FAILURE: 'PRICES_INSERT_FAILURE',

    PRICES_DELETE_REQUEST: 'PRICES_DEL_REQUEST',
    PRICES_DELETE_SUCCESS: 'PRICES_DEL_SUCCESS',
    PRICES_DELETE_FAILURE: 'PRICES_DEL_FAILURE',

    PRICES_UPDATE_REQUEST: 'PRICES_UPDATE_REQUEST',
    PRICES_UPDATE_SUCCESS: 'PRICES_UPDATE_SUCCESS',
    PRICES_UPDATE_FAILURE: 'PRICES_UPDATE_FAILURE',

    CONTENT_GETALL_REQUEST: 'CONTENT_GETALL_REQUEST',
    CONTENT_GETALL_SUCCESS: 'CONTENT_GETALL_SUCCESS',
    CONTENT_GETALL_FAILURE: 'CONTENT_GETALL_FAILURE',
    UPDATE_CONTENT_START:'UPDATE_CONTENT_START',
    UPDATE_CONTENT_FAILURE:'UPDATE_CONTENT_FAILURE',
    UPDATE_CONTENT_SUCCESS:'UPDATE_CONTENT_SUCCESS',

    REVIEWS_GETALL_REQUEST: 'REVIEW_GETALL_REQUEST',
    REVIEWS_GETALL_SUCCESS: 'REVIEW_GETALL_SUCCESS',
    REVIEWS_GETALL_FAILURE: 'REVIEW_GETALL_FAILURE',

    ENQUEUE_SNACKBAR: 'ENQUEUE_SNACKBAR',
    REMOVE_SNACKBAR: 'REMOVE_SNACKBAR',
    
    RESERVATION_RESET: 'RESERVATION_RESET',
    RESERVATION_GETALL_REQUEST: 'RESERVATION_GETALL_REQUEST',
    RESERVATION_GETALL_SUCCESS: 'RESERVATION_GETALL_SUCCESS',
    RESERVATION_GETALL_FAILURE: 'RESERVATION_GETALL_FAILURE',

    RESERVATIONS_GETALL_REQUEST: 'RESERVATION_GETALL_REQUEST',
    RESERVATIONS_GETALL_SUCCESS: 'RESERVATION_GETALL_SUCCESS',
    RESERVATIONS_GETALL_FAILURE: 'RESERVATION_GETALL_FAILURE',

    RESERVATION_GET_REQUEST: 'RESERVATION_GET_REQUEST',
    RESERVATION_GET_SUCCESS: 'RESERVATION_GET_SUCCESS',
    RESERVATION_GET_FAILURE: 'RESERVATION_GET_FAILURE',

    RESERVATION_INSERT_START: 'RESERVATION_INSERT_START',
    RESERVATION_INSERT_FAILURE: 'RESERVATION_INSERT_FAILURE',
    RESERVATION_INSERT_SUCCESS: 'RESERVATION_INSERT_SUCCESS',

    RESERVATIONS_DELETE_SUCCESS: 'RESERVATIONS_DELETE_SUCCESS',
    RESERVATIONS_DELETE_FAILURE: 'RESERVATIONS_DELETE_FAILURE',
    RESERVATIONS_DELETE_REQUEST: 'RESERVATIONS_DELETE_REQUEST',

    CONTACT_INSERT_START: 'CONTACT_INSERT_START',
    CONTACT_INSERT_FAILURE: 'CONTACT_INSERT_FAILURE',
    CONTACT_INSERT_SUCCESS: 'CONTACT_INSERT_SUCCESS',
    
    RESERVATIONS_UPDATE_SUCCESS: 'RESERVATIONS_UPDATE_SUCCESS',
    RESERVATIONS_UPDATE_FAILURE: 'RESERVATIONS_UPDATE_FAILURE',
    RESERVATIONS_UPDATE_REQUEST: 'RESERVATIONS_UPDATE_REQUEST',
};
