import axios from "axios";
import { config } from "_constants";
import { storeConstants } from "store";
import { authHeader } from "_helpers";
import * as actions from "store/actions";

//#region  GET
export const getReservationStart = (token = void 0) => {
  return {
    type: storeConstants.RESERVATION_GET_REQUEST,
    token: token
  };
};
export const getReservationsStart = (token = void 0) => {
  return {
    type: storeConstants.RESERVATION_GETALL_REQUEST,
    token: token
  };
};

export const getReservationFail = error => {
  return {
    type: storeConstants.RESERVATION_GET_FAILURE,
    error: error.message ? error.message : error.toString()
  };
};
export const getReservationsFail = error => {
  return {
    type: storeConstants.RESERVATIONS_GETALL_FAILURE,
    error: error.message ? error.message : error.toString()
  };
};

export const getReservationSuccess = item => {
  return {
    type: storeConstants.RESERVATION_GET_SUCCESS,
    item: item
  };
};
export const getReservationsSuccess = items => {
  return {
    type: storeConstants.RESERVATIONS_GETALL_SUCCESS,
    items: items
  };
};

export const getReservations = () => {
  return dispatch => {
    dispatch(getReservationsStart());
    let url = `${config.API_URL}/Reservations/Admin`;

    axios
      .get(url, { headers: authHeader() })
      .then(res => {
        const fetchedData = [];
        for (let key in res.data) {
          fetchedData.push({ ...res.data[key], id: res.data[key].id });
        }
        dispatch(getReservationsSuccess(fetchedData));
      })
      .catch(err => {
        dispatch(getReservationsFail(err));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se načíst seznam rezervací\n${err}`, options: { variant: "error" } }));
      });
  };
};
export const getReservation = id => {
  if (!id) return dispatch => dispatch(getReservationSuccess(void 0));

  return dispatch => {
    dispatch(getReservationStart());
    let url = `${config.API_URL}/Reservations/${id}`;

    axios
      .get(url, { headers: authHeader() })
      .then(res => {
        dispatch(getReservationSuccess(res.data));
      })
      .catch(err => {
        dispatch(getReservationFail(err));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se načíst rezervaci\n${err}`, options: { variant: "error" } }));
      });
  };
};
//#endregion GET

//#region  POST
export const insertReservationSuccess = Reservation => {
  return {
    type: storeConstants.RESERVATION_INSERT_SUCCESS,
    item: Reservation
  };
};

export const insertReservationFail = error => {
  return {
    type: storeConstants.RESERVATION_INSERT_FAILURE,
    error: error
  };
};

export const insertReservationStart = () => {
  return {
    type: storeConstants.RESERVATION_INSERT_START
  };
};

export const insertReservation = (Reservation, token) => {
  return dispatch => {
    dispatch(insertReservationStart());
    let url = `${config.API_URL}/Reservations`;
    axios
      .post(url, Reservation, { headers: authHeader() })
      .then(response => {
        dispatch(insertReservationSuccess(Reservation));
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        dispatch(actions.getPeriods(month, year, token));
        dispatch(actions.enqueueSnackbar({ message: "Rezervace byla úspěšně vytvořena.", options: { variant: "success" } }));
      })
      .catch(error => {
        dispatch(insertReservationFail(error));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se vytvořit rezervaci.\n${error}`, options: { variant: "error" } }));
      });
  };
};

export const insertContactSuccess = Contact => {
  return {
    type: storeConstants.CONTACT_INSERT_SUCCESS,
    item: Contact
  };
};

export const insertContactFail = error => {
  return {
    type: storeConstants.CONTACT_INSERT_FAILURE,
    error: error
  };
};

export const insertContactStart = () => {
  return {
    type: storeConstants.CONTACT_INSERT_START
  };
};

export const insertContact = Contact => {
  return dispatch => {
    dispatch(insertContactStart());
    let url = `${config.API_URL}/Contact`;
    axios
      .post(url, Contact, { headers: authHeader() })
      .then(response => {
        dispatch(insertContactSuccess(Contact));
      })
      .catch(error => {
        dispatch(insertContactFail(error));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se odeslat kontaktní formulář.\n${error}`, options: { variant: "error" } }));
      });
  };
};
//#endregion POST

export const updateReservationSuccess = reservation => {
  return {
    type: storeConstants.RESERVATIONS_UPDATE_SUCCESS,
    item: reservation
  };
};

export const updateReservationFail = error => {
  return {
    type: storeConstants.RESERVATIONS_UPDATE_FAILURE,
    error: error
  };
};

export const updateReservationStart = () => {
  return {
    type: storeConstants.RESERVATIONS_UPDATE_REQUEST
  };
};

export const updateReservation = (reservation, token) => {
  return dispatch => {
    dispatch(updateReservationStart());
    let url = `${config.API_URL}/Reservations/${reservation.id}`;
    axios
      .put(url, reservation, { headers: authHeader() })
      .then(response => {
        dispatch(updateReservationSuccess(reservation));
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        dispatch(actions.getPeriods(month, year, token));
        dispatch(actions.enqueueSnackbar({ message: "Rezerzvace byla úspěšně aktualizována.", options: { variant: "success" } }));
      })
      .catch(error => {
        dispatch(updateReservationFail(error));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se aktualizovat rezervaci\n${error}`, options: { variant: "error" } }));
      });
  };
};
//#endregion PUT

//#region  DELETE
export const deleteReservationSuccess = id => {
  return {
    type: storeConstants.RESERVATIONS_DELETE_SUCCESS,
    id: id
  };
};

export const deleteReservationFail = error => {
  return {
    type: storeConstants.RESERVATIONS_DELETE_FAILURE,
    error: error
  };
};

export const deleteReservationStart = () => {
  return {
    type: storeConstants.RESERVATIONS_DELETE_REQUEST
  };
};

export const deleteReservation = (id, token) => {
  if (!id || id < 0) {
    return dispatch => {
      dispatch(deleteReservationFail("Záznam pro smazání nebyl nalezen podle ID"));
      dispatch(actions.enqueueSnackbar({ message: "Rezervaci nebylo možné smazat.", options: { variant: "error" } }));
    };
  }

  return dispatch => {
    dispatch(deleteReservationStart());
    let url = `${config.API_URL}/Reservations/${id}`;
    axios
      .delete(url, { headers: authHeader() })
      .then(response => {
        dispatch(deleteReservationSuccess(id));
        const month = new Date().getMonth() + 1;
        const year = new Date().getFullYear();
        dispatch(actions.getPeriods(month, year, token));
        dispatch(actions.enqueueSnackbar({ message: "Rezervace byla úspěšně smazána.", options: { variant: "success" } }));
      })
      .catch(error => {
        dispatch(deleteReservationFail(error));
        dispatch(actions.enqueueSnackbar({ message: "Položka ceníku se nepodařilo smazat.\n", options: { variant: "error" } }));
      });
  };
};
//#endregion DELETE

export const resetReservation = () => {
  return dispatch => {
    dispatch(resetReservationSuccess());
  };
};
export const resetReservationSuccess = () => {
  return {
    type: storeConstants.RESERVATION_RESET
  };
};
