
const initialState = {
  phone: "+420 775 352 007",
  email: "info@chalupa-ostruzna.cz",
  owner: "Michaela Sasáková",
  ic: 88848078,
  street: "Ostružná 24",
  zip: "Branná, 788 25"

};
export function contact(state = initialState, action) {
  return state;
}
