import React from "react";
import dateFns from "date-fns";
import cs from "date-fns/locale/cs";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Hidden from "@material-ui/core/Hidden";
import * as actions from "store/actions";

import { withStyles } from "@material-ui/core/styles";
import { Aux } from "components/HOC";
import { ContentLoader } from "components/Common";
import { connect } from "react-redux";
import { toPrice } from "_helpers";

import "./PriceList.css";

const styles = {};

class PriceList extends React.Component {
  state = {};

  componentDidMount() {
    this.props.onFetchPrices();
  }

  render() {
    return (
      <Aux>
        {this.props.loading ? (
          <ContentLoader />
        ) : (
          <div className="price-list">
            <Table className="table">
              <Hidden xsDown>
                <TableHead>
                  <TableRow>
                    <TableCell>Datum od</TableCell>
                    <TableCell>Datum do</TableCell>
                    <TableCell numeric>Cena</TableCell>
                    <Hidden mdDown>
                      <TableCell>Poznámka</TableCell>
                    </Hidden>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.prices.map(row => {
                    return (
                      <TableRow key={row.id} className="row">
                        <TableCell scope="row">
                          {dateFns.format(row.dateFrom, "DD.MM.YYYY", {
                            locale: cs
                          })}
                        </TableCell>
                        <TableCell>
                          {dateFns.format(row.dateTo, "DD.MM.YYYY", {
                            locale: cs
                          })}
                        </TableCell>
                        <TableCell numeric>{toPrice(row.price)}</TableCell>
                        <Hidden mdDown>
                          <TableCell>{row.note}</TableCell>
                        </Hidden>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Hidden>
              <Hidden smUp>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Datum od /<br /> Datum do
                    </TableCell>
                    <TableCell numeric>Cena</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.prices.map(row => {
                    return (
                      <TableRow key={row.id} className="row">
                        <TableCell scope="row">
                          <span className="inline">
                            {dateFns.format(row.dateFrom, "DD.MM.YYYY", {
                              locale: cs
                            })}
                          </span>
                          <span className="inline">
                            {dateFns.format(row.dateTo, "DD.MM.YYYY", {
                              locale: cs
                            })}
                          </span>
                        </TableCell>
                        <TableCell numeric>{toPrice(row.price)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Hidden>
            </Table>
            <div className="footnote">* Uvedené ceny odpovídají minimální době pronájmu 7 dní (od soboty do soboty).</div>
            <div className="footnote">* Zkrácený termín pouze mimo hlavní sezónu po dohodě.</div>
          </div>
        )}
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    prices: state.prices.prices,
    loading: state.prices.loading,
    dataArrived: state.prices.dataArrived,
    token: state.authentication.token,
    userId: state.authentication.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPrices: () => dispatch(actions.getPrices())
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PriceList)
);
