import React from "react";
import Carousel from "react-slick";
import withStyles from "@material-ui/core/styles/withStyles";
import * as actions from "store/actions";

import { ContentLoader, Review } from "components/Common";
import { connect } from "react-redux";

import "./ReviewCarousel.css";
import carouselStyle from "./reviewCarouselStyle.jsx";

class ReviewCarousel extends React.Component {
  componentDidMount() {
    this.props.onFetchReviews();
  }

  render() {
    const { classes } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 8000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div>
        <div style={{ position: "relative" }}>
          <Carousel {...settings} className={classes.mainCarousel}>
            {this.props.loading ? (
              <ContentLoader />
            ) : (
              this.props.reviews.map(item => <Review key={item.id} {...item} />)
            )}
          </Carousel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    reviews: state.reviews.items,
    loading: state.reviews.loading,
    dataArrived: state.reviews.dataArrived
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchReviews: () => dispatch(actions.getReviews())
  };
};

export default withStyles(carouselStyle)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReviewCarousel)
);
