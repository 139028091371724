import axios from "axios";
import { config } from "_constants";
import { storeConstants } from "store";
import { authHeader } from "_helpers";
import * as actions from "store/actions";
import * as snackbar from "./snackbar.actions"

export const getContentStart = (token = void 0) => {
  return {
    type: storeConstants.CONTENT_GETALL_REQUEST,
    token: token
  };
};

export const getContentFail = error => {
  snackbar.logError(`Nepodařilo se získat seznam statických textů`,error);

  return {
    type: storeConstants.CONTENT_GETALL_FAILURE,
    error: error.message ? error.message : error.toString()
  };
};

export const getContentSuccess = items => {
  return {
    type: storeConstants.CONTENT_GETALL_SUCCESS,
    items: items
  };
};

export const getContent = () => {
  return dispatch => {
    dispatch(getContentStart());
    let url = `${config.API_URL}/Content`;

    axios
      .get(url, { headers: authHeader() })
      .then(res => {
        const fetchedData = [];
        for (let key in res.data) {
          fetchedData.push({
            ...res.data[key],
            id: res.data[key].id
          });
        }
        dispatch(getContentSuccess(fetchedData));
      })
      .catch(err => {
        dispatch(getContentFail(err));
      });
  };
};

export const updateContentSuccess = (id, text) => {
  return {
    type: storeConstants.UPDATE_CONTENT_SUCCESS,
    id: id,
    text: text
  };
};

export const updateContentFail = error => {


  return {
    type: storeConstants.UPDATE_CONTENT_FAILURE,
    error: error
  };
};

export const updateContentStart = () => {
  return {
    type: storeConstants.UPDATE_CONTENT_START
  };
};

export const updateContent = (key, text) => {
  return dispatch => {
    dispatch(updateContentStart());
    let url = `${config.API_URL}/Content/${key}`;
    axios
      .put(url, { key: key, text: text }, { headers: authHeader() })
      .then(response => {
        dispatch(updateContentSuccess(key, text));
        dispatch(actions.enqueueSnackbar({ message: "Obsah byl úspěšně aktualizován.", options: { variant: "success" } }));
      })
      .catch(error => {
        dispatch(updateContentFail(error));
        dispatch(actions.enqueueSnackbar({ message: `Nepodařilo se aktualizovat obsah\n${error}`, options: { variant: "error" } }));
      });
  };
};
