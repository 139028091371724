import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

import { ReviewCarousel } from "components/Common";

import "./ReviewBox.css";

export const ReviewBox = props => {
  let classes = "box-container";
  if (props.noPadding) classes += " no-padding";
  return (
    <Grid
      item
      xs={props.xs}
      md={props.md}
      sm={props.sm}
      lg={props.lg}
      className="review-box">
      <Card className={classes} style={{ height: "100%" }}>
        {props.loading ? <LinearProgress color="primary" /> : null}
        {props.title && <h2>{props.title}</h2>}
        <ReviewCarousel />
      </Card>
    </Grid>
  );
};

ReviewBox.defaultProps = {
  okTitleSettings: {
    variant: void 0,
    color: "primary"
  }
};

export default ReviewBox;