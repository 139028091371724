import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SaunaIcon from "components/Icons/Sauna";
import SkyIcon from "components/Icons/Sky";
import WifiIcon from "@material-ui/icons/Wifi";
import TVIcon from "@material-ui/icons/Tv";
import KitchenIcon from "@material-ui/icons/Kitchen";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import ParkingIcon from "@material-ui/icons/LocalParking";
import TerrainIcon from "@material-ui/icons/Terrain";
import SkyingIcon from "components/Icons/Skying";
import BikeIcon from "@material-ui/icons/DirectionsBike";
import React from "react";
import { withRouter } from "react-router-dom";
import "./Services.css";

class Services extends React.Component {
  render() {
    return (
      <Grid container className="services">
        <Grid item sm={12} xs={12} md={6}>
          <div className="service">
            <i><WifiIcon className="mf" /></i>
            <span className="caption">WiFi zdarma</span>
          </div>
          <div className="service">
            <i><TVIcon  className="mf"/></i>
            <span className="caption">TV na každém pokoji</span>
          </div>
          <div className="service">
            <i><KitchenIcon  className="mf"/></i>
            <span className="caption">Plně vybavená kuchyň</span>
          </div>
          <div className="service">
            <i>
              <SaunaIcon color="#3C4858" />
            </i>
            <span className="caption">Finská sauna zdarma</span>
          </div>
          <div className="service">
            <i><ParkingIcon  className="mf"/></i>
            <span className="caption">Parkování přímo u chaty</span>
          </div>
          <div className="service">
            <i><ChildFriendlyIcon  className="mf"/></i>
            <span className="caption">Vhodné i pro rodiny s malými dětmi</span>
          </div>
        </Grid>
        <Grid item sm={12} xs={12} md={6}>
          <div className="service">
            <i>
              <SkyingIcon color="#3C4858" />
            </i>
            <span className="caption">Lyžařský areál 50m od chaty</span>
          </div>
          <div className="service">
            <i><TerrainIcon  className="mf"/></i>

            <span className="caption">Možnost horské turistiky</span>
          </div>
          <div className="service">
            <i><BikeIcon  className="mf"/></i>
            <span className="caption">Možnost uskladnit horské kolo</span>
          </div>
          <div className="service">
            <i>
              <SkyIcon color="#3C4858" />
            </i>
            <span className="caption">Možnost uskladnit lyže, běžky či jiné zimní vybavení</span>
          </div>
        </Grid>
        <Grid container spacing={0} direction="column" alignItems="center" justify="center">
          <Button position="center" variant="contained" color="primary" size="large" className="button mainButton" onClick={() => this.props.history.push("/rezervace")}>
            Ceník / Rezervace
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Services);
