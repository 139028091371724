import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { HomeContentBox, ContentBox } from "components/Common";
import { Gallery1np } from "./Accomodation";
import * as images from "_constants/images";

class UbytovaniContent extends React.Component {
  images1np = [{ src: images.kitchen5, width: 4, height: 3 }, { src: images.kitchen2, width: 4, height: 3 }, { src: images.kitchen9, width: 4, height: 3 }, { src: images.kitchen10, width: 4, height: 3 }, { src: images.kitchen3, width: 4, height: 3 }, { src: images.kitchen1, width: 3, height: 4 }, { src: images.kitchen4, width: 3, height: 4 }, { src: images.kitchen7, width: 3, height: 4 }, { src: images.kitchen6, width: 3, height: 4 }, { src: images.kitchen8, width: 3, height: 4 }];
  images2np = [{ src: images.rooms5, width: 4, height: 3 }, { src: images.rooms1, width: 4, height: 3 }, { src: images.rooms3, width: 4, height: 3 }, { src: images.rooms2, width: 3, height: 4 }, { src: images.rooms4, width: 3, height: 4 }, { src: images.rooms7, width: 4, height: 3 }, { src: images.rooms6, width: 4, height: 3 }, { src: images.rooms8, width: 4, height: 3 }, { src: images.rooms9, width: 4, height: 3 }, { src: images.rooms10, width: 3, height: 4 }];

  images3np = [{ src: images.attics2, width: 4, height: 3 }, { src: images.attics1, width: 4, height: 3 }, { src: images.attics3, width: 4, height: 3 }, { src: images.attics4, width: 4, height: 3 }];
  imagesFloor = [{ src: images.floor2, width: 4, height: 3 }, { src: images.floor3, width: 3, height: 4 }, { src: images.floor4, width: 4, height: 3 }];

  render() {
    return (
      <Grid container>
        <HomeContentBox title="Ubytování" xs={12} lg={12} itemId="ubytovani" level="0">
        <Gallery1np photos={this.imagesFloor} columns={4} />

          <ContentBox title="Přízemí" itemId="ubytovani-prizemi" />
          
          <ContentBox title="1. Patro" itemId="ubytovani-1np">
            <Grid container>
              <Grid item lg={5}>
                <ContentBox title="Kuchyň" itemId="ubytovani-1np-kuchyn" level="2" />
                <ContentBox title="Koupelna" itemId="ubytovani-1np-koupelna" level="2" />
                <ContentBox title="Finská sauna" itemId="ubytovani-1np-sauna" level="2" />
              </Grid>
              <Grid item lg={7}>
                <Gallery1np photos={this.images1np} columns={5} />
              </Grid>
            </Grid>
          </ContentBox>
          <ContentBox title="2. Patro" itemId="ubytovani-2np">
            <Grid container>
              <Grid item lg={5}>
                <ContentBox title="Pokoje" itemId="ubytovani-2np-pokoje" level="2" />
                <ContentBox title="Koupelna" itemId="ubytovani-2np-koupelna" level="2" />
              </Grid>
              <Grid item lg={7}>
                <Gallery1np photos={this.images2np} columns={5} />
              </Grid>
            </Grid>
          </ContentBox>
          <ContentBox title="3. Patro" itemId="ubytovani-3np" />
          <Gallery1np photos={this.images3np} columns={4} />
        </HomeContentBox>
      </Grid>
    );
  }
}

export default UbytovaniContent;
