import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import NewIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from "@material-ui/icons/ErrorOutlineSharp";
import EditIcon from "@material-ui/icons/Edit";
import { PriceDialog } from "components/Admin";
import { ContentLoader } from "components/Common";
import { ConfirmationDialog } from "components/Admin";
import { Aux, MessageWindow } from "components/HOC";
import dateFns from "date-fns";
import cs from "date-fns/locale/cs";
import React from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import Hidden from "@material-ui/core/Hidden";
import "../../Common/Table.css";
import "./PriceListAdmin.css";
import { toPrice } from "_helpers";

class PriceListAdmin extends React.Component {
  onUpdatePrice = (values, actions) => {
    this.props.onSelectedItemEdit(values);
  };
  onInsertPrice = (values, actions) => {
    this.props.onSelectedItemNew(values);
    this.setState({ ...this.state, showNewDialog: false });
  };

  onConfirmedDelete = result => {
    try {
      if (result) this.props.onSelectedItemDelete(this.state.selectedId);
    } finally {
      this.setState({ ...this.state, showDeleteConfirm: false, selectedId: null });
    }
  };

  onNewClicked = () => this.setState({ ...this.state, showNewDialog: true });
  constructor(props) {
    super(props);
    this.state = {
      showNewDialog: false,
      showDeleteConfirm: false,
      selectedId: null
    };
    this.onNewClicked.bind(this);
    this.onUpdatePrice.bind(this);
    this.onInsertPrice.bind(this);
    this.onConfirmedDelete.bind(this);
  }

  componentDidMount() {
    this.props.onFetchPrices();
  }

  render() {
    return (
      <Aux>
        {this.props.loading ? (
          <ContentLoader />
        ) : (
          <div className="price-list-admin table">
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell>Datum od / Datum do</TableCell>
                  <Hidden smDown>
                    <TableCell>Poznámka</TableCell>
                  </Hidden>
                  <TableCell numeric>Cena</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.prices.map(row => {
                  return (
                    <TableRow key={row.id} className={"row" + row.conflict ? " conflict" : ""}>
                      <TableCell className="first">
                        <IconButton disabled={this.props.disableEdit ? true: void 0}
                          color="primary"
                          onClick={() => {
                            this.setState({ ...this.state, loading: true, showDialog: true });
                            this.props.onFetchPrice(row.id);
                          }}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell className="first">
                        <IconButton disabled={this.props.disableEdit ? true: void 0}
                          color="primary"
                          onClick={() => {
                            this.setState({ ...this.state, loading: true, showDeleteConfirm: true, selectedId: row.id });
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell scope="row" className="date">
                        <span className="inline">
                          {dateFns.format(row.dateFrom, "DD.MM.YYYY", {
                            locale: cs
                          })}
                        </span>
                        <span className="inline dash">-</span>
                        <span className="inline">
                          {dateFns.format(row.dateTo, "DD.MM.YYYY", {
                            locale: cs
                          })}
                          {row.conflict && (
                            <Tooltip title="Koncové datum je v konfliktu s následujícím termínem">
                              <WarningIcon className="warning" />
                            </Tooltip>
                          )}
                        </span>
                      </TableCell>
                      <Hidden smDown>
                        <TableCell scope="row">{row.note}</TableCell>
                      </Hidden>
                      <TableCell numeric>{toPrice(row.price)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Button variant="contained" color="primary" className="submit-button" onClick={this.onNewClicked}>
              <NewIcon /> Nový
            </Button>
          </div>
        )}
        <MessageWindow isOpen={this.props.selectedPrice} closed={this.props.onSelectedItemCleared} title="Editovat ceník">
          <PriceDialog item={this.props.selectedPrice} onSubmit={this.onUpdatePrice} closed={this.props.onSelectedItemCleared} />
        </MessageWindow>
        <MessageWindow isOpen={this.state.showNewDialog} closed={() => this.setState({ ...this.state, showNewDialog: false })} title="Nová cena">
          <PriceDialog onSubmit={this.onInsertPrice} closed={() => this.setState({ ...this.state, showNewDialog: false })} />
        </MessageWindow>
        <ConfirmationDialog open={this.state.showDeleteConfirm} onClose={this.onConfirmedDelete} />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    prices: state.prices.prices,
    selectedPrice: state.prices.selectedItem,
    loading: state.prices.loading,
    dataArrived: state.prices.dataArrived,
    token: state.authentication.token,
    userId: state.authentication.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPrices: () => dispatch(actions.getPrices()),
    onFetchPrice: id => dispatch(actions.getPrice(id)),
    onSelectedItemCleared: () => dispatch(actions.getPrice(null)),
    onSelectedItemEdit: item => dispatch(actions.updatePrice(item)),
    onSelectedItemNew: item => dispatch(actions.insertPrice(item)),
    onSelectedItemDelete: item => dispatch(actions.deletePrice(item))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceListAdmin);
