import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";

import { Calendar, PriceList, ReservationForm } from "components/pages/Reservations";
import { HomeContentBox } from "components/Common";

class RezervaceContent extends React.Component {
 
  render() {
    return (
      <Grid container>
        <HomeContentBox level="0" title="Rezervační formulář" itemId="reservation-form" xs={12} lg={7} methodLoading={this.props.loadingReservation}>
          <ReservationForm />
        </HomeContentBox>
        <HomeContentBox xs={12} lg={5} noPadding loading={this.props.loading} style={{ height: "inherit" }}>
          <Calendar noPending={true} />
        </HomeContentBox>
        <HomeContentBox level="0" title="Ceník" xs={12} lg={7} itemId="cenik" loading={this.props.loading}>
          <PriceList />
        </HomeContentBox>
      
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    loadingReservation: state.reservations.loadingItem
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RezervaceContent);


