import React from "react";
import dateFns from "date-fns";
import cs from "date-fns/locale/cs";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles'

import "./Calendar.css";

const styles = {
    tooltipGreen: {
        backgroundColor: 'green !important',
    },
    tooltipOrange: {
        backgroundColor: 'orange !important',
    },
    tooltipRed: {
        backgroundColor: 'darkred !important',
    }
};

class Day extends React.Component {
    state = {
        currentMonth: new Date(),
        selectedDate: new Date(),
        open: true
    };

    _checkDisabledClass() {
        return ["reserved", "inactive", "occupied"].indexOf(this.props.type) >= 0 ? "disabled" : "";
    }

    _getTooltipAditionalInfo(){
        const item = this.props.item;
        if (!item)
            return ""
        return `${item.name}<br/>${item.dateFrom}<br/>${item.dateTo}`;
    }

    _getTooltipTitle() {
        switch (this.props.type) {
            case "reserved": return "Termín čeká na potvrzení rezervace.";
            case "vacancy": return "Termín je možné rezervovat."
            case "half": return "Termín je možné rezervovat."
            case "half-reserved": return "Termín je možné rezervovat."
            case "half-inverted": return "Termín je možné rezervovat."
            case "half-inverted-reserved": return "Termín je možné rezervovat."
            case "occupied": return "Termín je už obsazen.";
            case "inactive":
            default:
                return ""
        }
    }
    _getTooltipClass() {
        const { classes } = this.props;
        switch (this.props.type) {
            case "reserved": return { tooltip: classes.tooltipOrange }
            case "half": return { tooltip: classes.tooltipGreen }
            case "half-reserved": return { tooltip: classes.tooltipGreen }
            case "vacancy": return { tooltip: classes.tooltipGreen }
            case "occupied": return { tooltip: classes.tooltipRed }
            case "inactive":
            default:
                return {  };
        }
    }
    get _isTooltipEnabled()
    {
        return ["reserved", "vacancy", "occupied"].indexOf(this.props.type) >= 0;
    }

    onDateClick = (day) => {
    }

    render() {
        const dateFormat = "D";
        const formattedDate = dateFns.format(this.props.day, dateFormat, { locale: cs });
        const cloneDay = this.props.day;

        const tooltipSettings={
            disableFocusListener: !this._isTooltipEnabled,
            disableHoverListener: !this._isTooltipEnabled,
            disableTouchListener: !this._isTooltipEnabled
        }

        return (
            <Tooltip title={this._getTooltipTitle()} classes={this._getTooltipClass()} {...tooltipSettings} >
                <div className="col cell">
                    <Grid item className={`cell-inner ${this._checkDisabledClass()} ${this.props.type}`}
                        key={this.props.day.toString()}
                        onClick={() => this.onDateClick(dateFns.parse(cloneDay))}
                    >
                        <span className={`number ${this.props.day.toDateString() === (new Date()).toDateString() ? "today": "" } `}>{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                    </Grid>
                </div>
            </Tooltip>
        )
    }
}

export default withStyles(styles)(Day);
