import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import React from "react";
import "./ConfirmationDialog.css";
function ConfirmationDialog(props) {
  return (
    <Dialog className="confirmation-dialog" disableBackdropClick disableEscapeKeyDown maxWidth="xs" aria-labelledby="confirmation-dialog-title" {...props}>
      <DialogTitle id="confirmation-dialog-title">{props.title ? props.title : "Smazat"}</DialogTitle>
      <DialogContent>{props.question ? props.question : "Opravdu chcete smazat záznam?"}</DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(true)} color="primary">
          {props.okTitle ? props.okTitle : "Ano"}
        </Button>
        <Button onClick={() => props.onClose(false)} color="primary">
          {props.cancelTitle ? props.cancelTitle : "Ne"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func
};

export default ConfirmationDialog;
