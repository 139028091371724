import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",

  },
  buttonSubmit: {
    height: "20px",
    width: "34px"

  },
  buttonSuccess: {
    backgroundColor: green[500],
    height: "20px",
    width: "34px",
    "&:hover": {
      backgroundColor: green[700]
    
  }

  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class SubmitButton extends React.Component {
  state = {
    loading: false,
    success: false
  };

  handleButtonClick = () => {
    if (!this.state.loading && this.props.action) {
      this.setState({ success: false, loading: true }, () => {
        //console.log("submitBtn", "start",this.state.loading);
        this.props
          .action()
          .then(() => {

            //reset do předchozího stavu
            setTimeout(() => {
              this.setState({ success: true, loading: false });
            }, 1000);
            setTimeout(() => {
              this.setState({ loading: false, success: false });
              this.props.onSuccess();
            }, 2000);
          })
          .catch(() => this.setState({ success: false, loading: false }));
      });
    }
  };
  render() {
    const { loading, success } = this.state;
    const { classes } = this.props;
    //const buttonClassname = success ? classes.buttonSuccess + " submit-button" : "submit-button";
    const buttonClassname = success ? classes.buttonSuccess : classes.buttonSubmit;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Fab
            id={this.props.id}
            color="primary"
            className={buttonClassname}
            onClick={this.handleButtonClick}>
            {success ? <CheckIcon style={{ fontSize: 18 }}/> : <SaveIcon  style={{ fontSize: 18 }}/>}
          </Fab>
          {loading && (
            <CircularProgress size={48} className={classes.fabProgress} />
          )}
        </div>
      </div>
    );
  }
}

SubmitButton.propTypes = {
  classes: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired
};

export default withStyles(styles)(SubmitButton);
