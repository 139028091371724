import {storeConstants} from "store";


export const logError = (message, error) =>{
    enqueueSnackbar({ message: message, options: { variant: "error" }});
    console.log(message, error);
}

export const enqueueSnackbar = (notification) => {
    return {
        type: 'ENQUEUE_SNACKBAR',
        notification: {
            key: new Date().getTime() + Math.random(),
            ...notification,
        },
    };
};
export const removeSnackbar = (key) => {
    return {
        type: storeConstants.REMOVE_SNACKBAR,
        key:key
    };
};

