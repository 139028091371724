import React from "react";
import dateFns from "date-fns";
import cs from "date-fns/locale/cs";
import Grid from "@material-ui/core/Grid";
import Day from "./Day";

import { Aux } from "components/HOC";
//import { storeConstants } from "store";
import * as actions from "store/actions";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import "./Calendar.css";

const styles = {
  tooltipGreen: {
    backgroundColor: "green !important"
  },
  tooltipRed: {
    backgroundColor: "darkred !important"
  }
};

class Calendar extends React.Component {
  state = {
    currentMonth: new Date(),
    selectedDate: new Date()
  };

  componentDidMount() {
    this.props.onFetchPeriods(this.props.noPending ? void 0 : this.props.token);
  }


  getDayComponent(day, monthStart, formattedDate) {
    return <Day key={day} day={day} type={this.getDayType(day, monthStart)} item={this.getDayItem(day, monthStart)} />;
  }

  getDayItem(day, monthStart) {
    if (!dateFns.isSameMonth(day, monthStart) || !this.props.dataArrived) return "inactive";
    var period = this.props.periods.filter(i => dateFns.isWithinRange(day, i.dateFrom, i.dateTo));
    if (period && period.length > 0) {
      return period[0];
    }
    return void 0
  }

  getDayType(day, monthStart) {
    if (!dateFns.isSameMonth(day, monthStart) || !this.props.dataArrived) return "inactive";
    var period = this.props.periods.filter(i => dateFns.isWithinRange(day, i.dateFrom, i.dateTo));
    //debug

    if (period && period.length > 0) {
      //zkontrolovat jestli je endDay && nextDay is vacant => pul den klikatelný
      const otherPeriods = this.props.periods.filter(i => i.id != period[0].id);
      if (dateFns.isEqual(day, period[0].dateFrom)) {
      
        const prevDay = dateFns.addDays(day, -1);
        const isPrevDayVacant = otherPeriods.some(i => dateFns.isWithinRange(prevDay, i.dateFrom, i.dateTo))
        if (!isPrevDayVacant) {
          return  period[0].bailPaid == false ? "half-inverted-reserved" : "half-inverted";
        }
      }
      if (dateFns.isEqual(day, period[0].dateTo)) {
        const nextDay = dateFns.addDays(day, 1);
        const isNextDayVacant = otherPeriods.some(i => dateFns.isWithinRange(nextDay, i.dateFrom, i.dateTo))
        if (!isNextDayVacant) {
          return  period[0].bailPaid == false ? "half-reserved" : "half";
        }

      }
      return period[0].bailPaid == false ? "reserved" : "occupied";
    }
    return "vacancy";
  }

  renderHeader() {
    const dateFormat = "MMMM YYYY";

    return (
      <Aux>
        <Grid container direction="row" justify="center" alignItems="center" className="header row flex-middle">
          <Grid item className="col col-start">
            <div className="icon" onClick={this.prevMonth}>
              chevron_left
            </div>
          </Grid>
          <Grid item xs className="col col-center">
            {dateFns.format(this.state.currentMonth, dateFormat, {
              locale: cs
            })}
          </Grid>
          <Grid item className="col col-end" onClick={this.nextMonth}>
            <div className="icon">chevron_right</div>
          </Grid>
        </Grid>
      </Aux>
    );
  }

  renderDays() {
    const dateFormat = "dddd";
    const days = [];

    let startDate = dateFns.startOfWeek(this.state.currentMonth, {
      weekStartsOn: 1
    });

    for (let i = 0; i < 7; i++) {
      days.push(
        <Grid item xs className="col col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat, {
            locale: cs
          })}
        </Grid>
      );
    }

    return (
      <Grid container direction="row" justify="center" alignItems="center" className="days row">
        {days}
      </Grid>
    );
  }

  renderCells() {
    const { currentMonth } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = dateFns.endOfWeek(monthEnd);
    const rows = [];

    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push(this.getDayComponent(day, monthStart));
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <Grid container direction="row" justify="center" alignItems="center" className="row" key={day.toString()}>
          {days}
        </Grid>
      );
      days = [];
    }
    return (
      <Grid container direction="row" justify="center" alignItems="center" className="body">
        {rows}
      </Grid>
    );
  }

  onDateClick = day => {
    this.setState({
      selectedDate: day
    });
  };

  nextMonth = () => {
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
    });
  };

  prevMonth = () => {
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1)
    });
  };

  render() {
    return (
      <Grid container className="calendar" direction="row" justify="center" alignItems="center">
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    periods: state.calendar.periods,
    loading: state.calendar.loading,
    dataArrived: state.calendar.dataArrived,
    token: state.authentication.token,
    userId: state.authentication.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPeriods: (token) => dispatch(actions.getPeriods(token))
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Calendar)
);
