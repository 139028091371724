//import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import { HomeContentBox } from "components/Common";
import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import * as images from "_constants/images";

class FotogalerieContent extends React.Component {
  images = [
    { original: images.img2, thumbnail: images.img2b },
    { original: images.img3, thumbnail: images.img3b },
    { original: images.img4, thumbnail: images.img4b },
    { original: images.img5, thumbnail: images.img5b },
    { original: images.img6, thumbnail: images.img6b },
    { original: images.img7, thumbnail: images.img7b },
    { original: images.img8, thumbnail: images.img8b },
    { original: images.img9, thumbnail: images.img9b },
    { original: images.img10, thumbnail: images.img10b },
    { original: images.img11, thumbnail: images.img11b },
    { original: images.img12, thumbnail: images.img12b },
    { original: images.img13, thumbnail: images.img13b },
    { original: images.img14, thumbnail: images.img14b },
    { original: images.img15, thumbnail: images.img15b },
    { original: images.img16, thumbnail: images.img16b },
    { original: images.img17, thumbnail: images.img17b },
    { original: images.img18, thumbnail: images.img18b },
    { original: images.img19, thumbnail: images.img19b },
    { original: images.img20, thumbnail: images.img20b },
    { original: images.img21, thumbnail: images.img21b },
    { original: images.img22, thumbnail: images.img22b },
    { original: images.img23, thumbnail: images.img23b },
  ];

  componentDidMount() {
  }
  render() {
    return (
      <Grid container>
        <HomeContentBox level="0" title="Fotogalerie" xs={12} lg={12}>
          <ImageGallery items={this.images} lazyLoad={true} />
        </HomeContentBox>
      </Grid>
    );
  }
}


export default FotogalerieContent;
