import React from "react";

const Sauna = props => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="1em" height="1em" viewBox="0 0 412 512">
      <g>
        <g>
          <path fill={props.color} strokeWidth="10" stroke={props.color}
            d="M120.867,65.219c-32.706,0-59.313,26.608-59.313,59.313c0,32.705,26.608,59.313,59.313,59.313
			s59.313-26.607,59.313-59.313C180.18,91.826,153.573,65.219,120.867,65.219z M120.867,153.845
			c-16.164,0-29.313-13.149-29.313-29.313s13.15-29.313,29.313-29.313s29.313,13.15,29.313,29.313
			C150.18,140.695,137.031,153.845,120.867,153.845z"
          />
        </g>
      </g>
      <g>
        <g>
          <path fill={props.color} strokeWidth="10" stroke={props.color}
            d="M370.103,462.965L317,313.198H214.098l-47.12-111.839H90.08L31.442,360.844c-2.37,6.447-2.863,13.327-1.586,19.888H0v30
			h253.927l50.01,83.415c6.821,11.376,18.995,17.849,31.577,17.849c4.975,0,10.016-1.012,14.812-3.133
			C367.802,501.135,376.488,480.975,370.103,462.965z M105.545,380.732l17.863-28.638l15.156,28.638H105.545z M338.192,481.426
			c-3.111,1.376-6.778,0.212-8.525-2.705l-58.748-97.989h-98.414L125.54,291.99l-53.271,85.405c-1.304,2.09-3.552,3.337-6.015,3.337
			c-3.217,0-5.023-1.9-5.813-3.032c-0.79-1.133-1.95-3.484-0.841-6.503l51.414-139.837h36.05l47.12,111.839h101.623l46.021,129.792
			C343.001,476.3,341.404,480.006,338.192,481.426z"
          />
        </g>
      </g>
      <g>
        <g>
          <path fill={props.color}
            d="M359.706,112.663c0-19.012-7.689-29.445-13.304-37.063c-4.647-6.306-7.453-10.113-7.453-19.265
			c0-9.153,2.806-12.959,7.454-19.267c5.613-7.618,13.303-18.051,13.303-37.064h-30c0,9.152-2.806,12.959-7.453,19.266
			c-5.614,7.618-13.304,18.051-13.304,37.064c0,19.012,7.689,29.445,13.304,37.063c4.647,6.306,7.453,10.113,7.453,19.265
			c0,9.155-2.806,12.962-7.454,19.27c-5.614,7.621-13.303,18.055-13.303,37.069c0,19.014,7.688,29.448,13.303,37.066
			c4.648,6.308,7.454,10.116,7.454,19.27h30c0-19.014-7.688-29.448-13.303-37.067c-4.648-6.308-7.454-10.115-7.454-19.27
			c0-9.155,2.806-12.961,7.454-19.27C352.017,142.111,359.706,131.677,359.706,112.663z"
          />
        </g>
      </g>
      <g>
        <g>
          <path fill={props.color}
            d="M435.853,112.662c0-19.012-7.689-29.445-13.304-37.063c-4.647-6.306-7.453-10.113-7.453-19.265
			c0-9.153,2.806-12.959,7.454-19.267c5.613-7.615,13.303-18.048,13.303-37.061h-30c0,9.152-2.806,12.959-7.453,19.266
			c-5.614,7.618-13.304,18.051-13.304,37.064c0,19.012,7.689,29.445,13.304,37.063c4.647,6.306,7.453,10.113,7.453,19.265
			c0,9.155-2.806,12.962-7.454,19.27c-5.614,7.619-13.303,18.053-13.303,37.066s7.688,29.448,13.303,37.066
			c4.648,6.308,7.454,10.116,7.454,19.27h30c0-19.014-7.688-29.448-13.303-37.067c-4.648-6.308-7.454-10.115-7.454-19.27
			c0-9.155,2.806-12.961,7.454-19.27C428.164,142.11,435.853,131.676,435.853,112.662z"
          />
        </g>
      </g>
      <g>
        <g>
          <path fill={props.color}
            d="M512,112.665c0-19.012-7.689-29.445-13.304-37.063c-4.647-6.306-7.453-10.113-7.453-19.265
			c0-9.153,2.806-12.959,7.453-19.267C504.311,29.452,512,19.019,512,0.006h-30c0,9.152-2.806,12.959-7.453,19.266
			c-5.614,7.618-13.304,18.051-13.304,37.064c0,19.012,7.689,29.445,13.304,37.063c4.647,6.306,7.453,10.113,7.453,19.265
			c0,9.155-2.806,12.962-7.454,19.27c-5.614,7.619-13.303,18.053-13.303,37.066s7.688,29.448,13.303,37.066
			c4.648,6.308,7.454,10.116,7.454,19.27h30c0-19.014-7.688-29.448-13.303-37.067c-4.648-6.308-7.454-10.115-7.454-19.27
			c0-9.155,2.806-12.961,7.454-19.27C504.312,142.113,512,131.679,512,112.665z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Sauna;
