import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { calendar } from './calendar.reducer';
import { prices } from './prices.reducer';
import { content } from './content.reducer';
import { reviews } from './reviews.reducer';
import { snackbar } from './snackbar.reducer';
import { contact } from './contact.reducer';
import { reservations } from './reservations.reducer';

const rootReducer = combineReducers({
  authentication,
  users,
  calendar,
  prices,
  content,
  reviews,
  snackbar,
  contact,
  reservations
});

export default rootReducer;