import { storeConstants } from "store";
const defaultState = {
    notifications: [],
};

export function snackbar(state = defaultState, action) {
    switch (action.type) {
        case storeConstants.ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...action.notification,
                    },
                ],
            };

        case storeConstants.REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };

        default:
            return state;
    }
};
