import { HomeContentBox } from "components/Common";
import React from "react";

//import "./ContentBox.css";
//import "draft-js/dist/Draft.css";

class ContentBox extends React.Component {
  render(){
    return(
        <HomeContentBox {...this.props} plain/>
    )
  }
};


export default (ContentBox);
