import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import WarningIcon from "@material-ui/icons/ErrorOutlineSharp";
import { ConfirmationDialog, ReservationDialog } from "components/Admin";
import { Aux, MessageWindow } from "components/HOC";
import dateFns from "date-fns";
import cs from "date-fns/locale/cs";
import React from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { toPrice } from "_helpers";
import { Button } from "@material-ui/core";
import NewIcon from "@material-ui/icons/Add";
import "../../Common/Table.css";
import "./ReservationList.css";

class ReservationList extends React.Component {
  onUpdatePrice = (values, actions) => {
    this.props.onSelectedItemEdit(values, this.props.token);
  };
  onInsertPrice = (values, actions) => {
    this.props.onSelectedItemNew(values, this.props.token);
    this.setState({ ...this.state, showNewDialog: false });
  };

  onConfirmedDelete = result => {
    try {
      if (result) this.props.onSelectedItemDelete(this.state.selectedId, this.props.token);
    } finally {
      this.setState({ ...this.state, showDeleteConfirm: false, selectedId: null });
    }
  };

  onNewClicked = () => this.setState({ ...this.state, showNewDialog: true });
  constructor(props) {
    super(props);
    this.state = {
      showNewDialog: false,
      showDeleteConfirm: false,
      selectedId: null
    };
    this.onNewClicked.bind(this);
    this.onUpdatePrice.bind(this);
    this.onInsertPrice.bind(this);
    this.onConfirmedDelete.bind(this);
  }

  renderControlsHeader() {
    return (
      <Aux>
        <TableCell />
        <TableCell />
      </Aux>
    );
  }
  renderRecordControls(row) {
    return (
      <Aux>
        <TableCell className="first">
          <IconButton
            color="primary"
            disabled={this.props.disableEdit ? true : void 0}
            onClick={() => {
              this.setState({ ...this.state, loading: true, showDialog: true });
              this.props.onFetchReservation(row.id);
            }}>
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell className="first">
          <IconButton
            color="primary"
            disabled={this.props.disableEdit ? true : void 0}
            onClick={() => {
              this.setState({ ...this.state, loading: true, showDeleteConfirm: true, selectedId: row.id });
            }}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </Aux>
    );
  }

  markConflicts = () => {
    const reservations = this.props.reservations;
    if (!reservations || reservations.length === 0) return;
    const arr = reservations.sort((a, b) => new Date(a.dateFrom) - new Date(b.dateFrom));
    //kontrola konfliktů
    for (let i = 0; i < arr.length - 1; i++) {
      const a = arr[i];
      const b = arr[i + 1];
      if (a.dateTo > b.dateFrom) {
        a.conflict = true;
        b.conflict = true;
      }
    }
    return arr;
  };
  render() {
    if (this.props.showConflicts) this.markConflicts();
    return (
      <Aux>
        <Table className="table">
          <TableHead>
            <TableRow>
              {this.renderControlsHeader()}
              <TableCell>Vytvořeno</TableCell>

              <TableCell>Jméno a Příjmení</TableCell>
              <TableCell>Datum Od / Do</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>Email</TableCell>

              <TableCell>Kauce</TableCell>

              <TableCell>Cena celkem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.reservations.map(row => {
              return (
                <TableRow key={row.id} className={"row" + row.conflict ? " conflict" : ""}>
                  {this.renderRecordControls(row)}
                  <TableCell>
                    {dateFns.format(row.created, "DD.MM.YYYY", {
                      locale: cs
                    })}
                  </TableCell>

                  <TableCell scope="row">{row.name}</TableCell>

                  <TableCell scope="row">
                    <span className="inline">
                      {dateFns.format(row.dateFrom, "DD.MM.YYYY", {
                        locale: cs
                      })}
                    </span>
                    <span className="inline dash">-</span>
                    <span className="inline">
                      {dateFns.format(row.dateTo, "DD.MM.YYYY", {
                        locale: cs
                      })}
                      {this.props.showConflicts && row.conflict && (
                        <Tooltip title="Koncové datum je v konfliktu s následujícím termínem">
                          <WarningIcon className="warning" />
                        </Tooltip>
                      )}
                    </span>
                  </TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>
                    <a href={"mailto:" + row.email}>{row.email}</a>
                  </TableCell>

                  <TableCell>
                    <FormControlLabel label={toPrice(row.bail)} control={<Checkbox disabled={this.props.disableEdit ? true : void 0} checked={row.bailPaid} color="primary" className={row.bailPaid && !this.props.disableEdit ? "checkbox-bail green" : "checkbox-bail"} />} />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel label={toPrice(row.price)} control={<Checkbox disabled={this.props.disableEdit ? true : void 0} checked={row.paid} color="primary" className={row.paid && !this.props.disableEdit ? "checkbox-bail green" : "checkbox-bail"} />} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Button variant="contained" color="primary" className="submit-button" onClick={this.onNewClicked}>
          <NewIcon /> Nová
        </Button>
        <MessageWindow isOpen={this.props.selectedPrice} closed={this.props.onSelectedItemCleared} title="Editovat rezervaci">
          <ReservationDialog item={this.props.selectedPrice} onSubmit={this.onUpdatePrice} closed={this.props.onSelectedItemCleared} />
        </MessageWindow>
        <MessageWindow isOpen={this.state.showNewDialog} closed={() => this.setState({ ...this.state, showNewDialog: false })} title="Nová rezervace">
          <ReservationDialog onSubmit={this.onInsertPrice} closed={() => this.setState({ ...this.state, showNewDialog: false })} />
        </MessageWindow>
        <ConfirmationDialog open={this.state.showDeleteConfirm} onClose={this.onConfirmedDelete} />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.reservations.loading,
    selectedPrice: state.reservations.selectedItem,
    dataArrived: state.reservations.dataArrived,
    token: state.authentication.token,
    userId: state.authentication.userId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchReservations: () => dispatch(actions.getReservations()),
    onFetchReservation: id => dispatch(actions.getReservation(id)),
    onSelectedItemCleared: () => dispatch(actions.getReservation(null)),
    onSelectedItemEdit: (item, token) => dispatch(actions.updateReservation(item, token)),
    onSelectedItemNew: (item, token) => dispatch(actions.insertReservation(item, token)),
    onSelectedItemDelete: (item, token) => dispatch(actions.deleteReservation(item, token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationList);
