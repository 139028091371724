import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// import green from "@material-ui/core/colors/green";
// import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Aux } from "components/HOC";
import React from "react";
import {themeDialogButtons} from "_helpers";

import "./MessageWindow.css";

const MessageWindow = props => {

  return !props.isOpen ? null : (
    <Grid container className="backdrop" style={{zIndex:99}}>
      <Grid item className={"message-window"} xs={10} sm={8} md={6} lg={4}>
        <Aux>
          <Card raised={true}>
            <CardHeader
              className="header-success"
              title={props.title ? <h2>{props.title}</h2> : void 0}
              action={
                <IconButton onClick={()=>props.closed()}>
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent>
              <Aux>{props.children}</Aux>
            </CardContent>
            { (props.okTitle || props.cancelTitle) && 
            <CardActions disableActionSpacing>
              <MuiThemeProvider theme={themeDialogButtons}>
                {props.okTitle && <Button type="submit" onClick={()=>props.submitted()} variant="contained" color="primary" className="submit-button">
                  {props.okTitle}
                </Button>}
                {props.cancelTitle && <Button onClick={()=> props.closed()} variant="contained" color="secondary" className="submit-button">
                  {props.cancelTitle}
                </Button>}
              </MuiThemeProvider>
            </CardActions>
            }
          </Card>
        </Aux>
      </Grid>
    </Grid>
  );
};

export default MessageWindow;
