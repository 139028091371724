import { updateObject } from "_helpers/utility";
import { storeConstants } from "store";

const initialState = {
  items: [],
  loading: false,
  dataArrived: false,
  failure: false,
  reservationSuccess: false,
  contactSuccess: false,
  selectedItem: void 0,
  loadingItem: false
};
const sortItems = reservations => {
  if (!reservations || reservations.length === 0) return [];
  const arr = reservations.sort((a, b) => new Date(a.dateFrom) - new Date(b.dateFrom));
  //kontrola konfliktů
  // for (let i = 0; i < arr.length - 1; i++) {
  //   const a = arr[i];
  //   const b = arr[i + 1];
  //   if (a.dateTo >= b.dateFrom) {
  //     a.conflict = true;
  //     b.conflict = true;
  //   }
  // }
  return arr;
};

export function reservations(state = initialState, action) {
  switch (action.type) {
    case storeConstants.RESERVATIONS_GETALL_REQUEST:
      return updateObject(state, { loading: true, items: [] });
    case storeConstants.RESERVATIONS_GETALL_SUCCESS:
      return updateObject(state, {
        loading: false,
        items: sortItems(action.items),
        dataArrived: true
      });

    case storeConstants.RESERVATION_GETALL_FAILURE:
      return updateObject(state, { loading: false, items: [] });

    //get
    case storeConstants.RESERVATION_GET_REQUEST:
      return updateObject(state, { loadingItem: true, selectedItem: void 0 });
    case storeConstants.RESERVATION_GET_SUCCESS:
      return updateObject(state, {
        loadingItem: false,
        selectedItem: action.item,
        dataArrived: true
      });
    case storeConstants.RESERVATION_GET_FAILURE:
      return updateObject(state, { loadingItem: false, selectedItem: void 0 });

    //insert reservation
    case storeConstants.RESERVATION_RESET:
      return updateObject(state, { reservationSuccess: false, contactSuccess: false, loadingItem: false, selectedItem: void 0 });
    case storeConstants.RESERVATION_INSERT_FAILURE:
      return updateObject(state, { reservationSuccess: false, loadingItem: false, selectedItem: void 0 });
    case storeConstants.RESERVATION_INSERT_START:
      return updateObject(state, { reservationSuccess: false, loadingItem: true, selectedItem: void 0 });
    case storeConstants.RESERVATION_INSERT_SUCCESS:
      return updateObject(state, {
        reservationSuccess: true,
        loadingItem: false,
        items: sortItems([...state.items, action.item]),
        dataArrived: true
      });
    //insert contact
    case storeConstants.CONTACT_INSERT_FAILURE:
      return updateObject(state, { contactSuccess: false, loadingItem: false, selectedItem: void 0 });
    case storeConstants.CONTACT_INSERT_START:
      return updateObject(state, { contactSuccess: false, loadingItem: true, selectedItem: void 0 });
    case storeConstants.CONTACT_INSERT_SUCCESS:
      return updateObject(state, {
        contactSuccess: true,
        loadingItem: false,
        dataArrived: true
      });
    //update
    case storeConstants.RESERVATIONS_UPDATE_REQUEST:
      return updateObject(state, { loadingItem: true, selectedItem: void 0 });
    case storeConstants.RESERVATIONS_UPDATE_SUCCESS:
      return updateObject(state, {
        loadingItem: false,
        items: sortItems([...state.items.filter(i => i.id !== action.item.id), action.item]),
        dataArrived: true
      });
    case storeConstants.RESERVATIONS_UPDATE_FAILURE:
      return updateObject(state, { loadingItem: false, selectedItem: void 0 });
    //delete
    case storeConstants.RESERVATIONS_DELETE_REQUEST:
      return updateObject(state, { loadingItem: true, selectedItem: void 0 });
    case storeConstants.RESERVATIONS_DELETE_SUCCESS:
    let newItems = [...state.items];
        let item =newItems.filter(i => i.id === action.id);
        if (item && item.length >0)
          item[0].deleted = true
      return updateObject(state, {
        loadingItem: false,
        items: sortItems([...newItems]),
        dataArrived: true
      });
    case storeConstants.RESERVATIONS_DELETE_FAILURE:
      return updateObject(state, { loadingItem: false, selectedItem: void 0 });

    default:
      return state;
  }
}
