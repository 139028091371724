import React from "react";
import classNames from 'classnames/bind';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";

import { Parallax } from "components/Layout/MainPage";
import { GridContainer, GridItem} from "components/UI";
import { Aux} from "components/HOC";
import { NavLink } from "react-router-dom";

import headerStyle from "./headerStyle.jsx";
import Logo from "./logo";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      logoColor: props.logoColor1,
      logoColorMountain: props.mountainColor1,
      mountainColorHover: props.mountainColorHover,
      mountainColor: props.mountainColor2,
      currentMountainColor: props.mountainColor2
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.headerColorChange = this.headerColorChange.bind(this);
    this.logoMouseEnter = this.logoMouseEnter.bind(this);
    this.logoMouseLeave = this.logoMouseLeave.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }
  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
  }

  headerColorChange() {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    let logoColor1;
    let mountainColorHover;
    let mountainColor;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
      logoColor1 = this.props.logoColor2;
      mountainColorHover = this.props.mountainColorHover;
      mountainColor = this.props.mountainColor1;
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
      logoColor1 = this.props.logoColor1;
      mountainColorHover = this.props.mountainColorHover; //this.props.mountainColor1;
      mountainColor = this.props.mountainColor2;
    }
    //updateObject(this.state,{logoColor:logoColor1, mountainColorHover: mountainColorHover});
    this.setState({
      logoColor: logoColor1,
      mountainColorHover: mountainColorHover,
      mountainColor: mountainColor
    });
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }

  logoMouseEnter = () => {
    
    this.setState({ currentMountainColor: this.state.mountainColorHover });
  };
  logoMouseLeave = () => {
    
    this.setState({ currentMountainColor: this.state.mountainColor });
  };

  render() {
    const {
      classes,
      color,
      rightLinks,
      leftLinks,
      fixed,
      absolute
    } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed
    });
    const brandComponent = (
      <NavLink
        className={classes.brandComponent}
        to="/"
        onMouseEnter={this.logoMouseEnter}
        onMouseLeave={this.logoMouseLeave}
      >
        <Hidden smDown>
          <Logo
            height="3em"
            fill={this.state.logoColor}
            mountainColor={this.state.currentMountainColor}
          />
        </Hidden>
        <Hidden mdUp>
          <Logo
            height="3em"
            fill={this.state.logoColor}
            mountainColor={this.state.currentMountainColor}
          />
        </Hidden>
      </NavLink>
    );
    return (
      <Aux>
        <AppBar className={appBarClasses}>
          <Toolbar className={classes.container}>
            {leftLinks !== undefined ? brandComponent : null}
            <div className={classes.flex}>
              {leftLinks !== undefined ? (
                <Hidden smDown implementation="css">
                  {leftLinks}
                </Hidden>
              ) : (
                brandComponent
              )}
            </div>
            <Hidden smDown implementation="css">
              {rightLinks}
            </Hidden>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </Toolbar>
          <Hidden mdUp implementation="css">
            <Drawer 
              variant="temporary"
              anchor={"right"}
              open={this.state.mobileOpen}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={this.handleDrawerToggle}
            >
              <div
                className={classes.appResponsive}
                onClick={this.handleDrawerToggle}
              >
                <NavLink
                  className={classes.brandComponent}
                  to="/"
                  onMouseEnter={this.logoMouseEnter}
                  onMouseLeave={this.logoMouseLeave}
                >
                  <Logo
                    fill={this.props.logoColor2}
                    mountainColor={this.state.currentMountainColor}
                  />
                </NavLink>
                {leftLinks}
                {rightLinks}
              </div>
            </Drawer>
          </Hidden>
        </AppBar>
        <Parallax filter image={require("assets/img/bg.jpg")}>
          <div className={classes.container}>
            <GridContainer className="grid-container page-motto">
                <GridItem xs={12} sm={12} md={6} className="grid-item">
                  <h1 className="title">
                    Báječná dovolená plná skvělých zážitků.
                  </h1>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8} className="grid-item">
                  <h4 className="motto"> 
                    Užijte si zimní počasí spolu s krásou jesenických hor, v blízkosti lyžařských vleků, spolu s množštvím turistyckých a běžkařských tras. Spoustu zábavy si užijí jak dospělí tak děti.
                  </h4>
                  <br />
                  </GridItem>
            </GridContainer>
          </div>
        </Parallax>
      </Aux>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

export default withStyles(headerStyle)(Header);
