import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Aux } from "components/HOC";
import { Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import * as Yup from "yup";
import { config } from "_constants";
import { themeDialogButtons } from "_helpers";
import "./ReservationDialog.css";
import dateFns from "date-fns";

 
class ReservationDialog extends React.Component {
  
  validatationScheme =  Yup.object().shape( {
    name: Yup.string()
      .min(4, "Zadejte jméno a příjmení")
      .max(255, "Jméno a příjmení jsou příliš dlouhé.")
      .required("Zadejte jméno a příjmení"),
    phone: Yup.string().required("Zadejte Vaše telefoní číslo"),
    email: Yup.string()
      .email("Zadejte platnou emailovou adresu")
      .required("Zadejte Vaši emailovou adresu"),
    dateFrom: Yup.date()
      .when("dateTo", (dateTo, schema) => dateTo && schema.max(dateTo, "Datum od musí být dříve než datum do"))
      .required("Zadejte datum od"),
    dateTo: Yup.date()
      .when("dateFrom", (dateFrom, schema) => dateFrom && schema.min(dateFrom, ""))
      .required("Zadejte datum do"),
    zipcode: Yup.number().required("Zadejte PSČ"),
    street: Yup.string()
      .min(2, "Zadejte ulici a č.p.")
      .max(255, "Název ulice a č.p. je příliš dlouhý!")
      .required("Zadejte ulici a č.p."),
    city: Yup.string()
      .min(2, "Zadejte město")
      .required("Zadejte město"),
    bail: Yup.number(),
    caution: Yup.number(),
    price: Yup.number(),
    paid: Yup.boolean(),
    bailPaid: Yup.boolean(),

  },  ["dateFrom", "dateTo"]);

  onDialogClosed = () => this.setState({ ...this.state, showDialog: false });

  constructor(props) {
    super(props);
    this.state = {
      showDialog: false
    };
    this.onDialogClosed.bind(this);
  }
  state = {};

  getRecomendedPrice(values, errors) {
    if (errors.dateFrom || errors.dateTo) return "";
    let days = this.countCertainDays([1], new Date(values.dateFrom), new Date(values.dateTo)); //spočítá pondělky v zadaném období
    if (days <= 0) days++; //alespoň 1 termín

    const validPrices = this.props.prices.filter(p => p.dateFrom <= values.dateFrom && p.dateTo <= values.dateFrom);
    if (!validPrices || validPrices.length === 0) {
      console.log("Nebyl nalez platný ceník pro zadané obobí", values.dateFrom, values.dateTo);
      return "";
    }
    return validPrices[0].price * days;
  }

  getRecomendedPrice2(dateFrom, dateTo) {
    //let days = this.countCertainDays([1], new Date(dateFrom), new Date(dateTo)); //spočítá pondělky v zadaném období
    const days = Math.ceil(dateFns.differenceInDays(new Date(dateTo),new Date(dateFrom))/7);
    //if (days <= 0) days++; //alespoň 1 termín

    const validPrices = this.props.prices.filter(p => new Date(p.dateFrom) <= dateFrom && new Date(p.dateTo) >= dateFrom);
    if (!validPrices || validPrices.length === 0) {
      console.log("Nebyl nalez platný ceník pro zadané obobí", dateFrom, dateTo);
      return "";
    }
    return validPrices[0].price * days;
  }

  countCertainDays(days, d0, d1) {
    var ndays = 1 + Math.round((d1 - d0) / (24 * 3600 * 1000));
    var sum = function(a, b) {
      return a + Math.floor((ndays + ((d0.getDay() + 6 - b) % 7)) / 7);
    };
    return days.reduce(sum, 0);
  }

  getDefaultValues() {
    if (!this.props.item) {
        return config.DEBUG ? { dateFrom: "2019-04-01", dateTo: "2019-04-05", name: "test joe", street: "abcd 14", city: "cityy", phone: "13456", zipcode: "12345", email: "vad@dal.com", note: "aaa" } :
         { dateFrom: "", dateTo: "", name: "", street: "", city: "", phone: "", email: "", note: "", zipcode: "" };
    }
    else {
      return {
        ...this.props.item,
        dateFrom: this.props.item.dateFrom.substring(0, 10),
        dateTo: this.props.item.dateTo.substring(0, 10),
        recommendedPrice: this.getRecomendedPrice2(new Date(this.props.item.dateFrom.substring(0, 10)),new Date(this.props.item.dateTo.substring(0, 10)))
      };
    }
  }

  render() {
    return (
      <Aux>
        <Formik
          validationSchema={this.validatationScheme}
          onSubmit={this.props.onSubmit}
          initialValues={this.getDefaultValues()}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, isSubmitting, touched }) => (
            <form className="reservation-form" onSubmit={handleSubmit}>
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField id="dateFrom" name="dateFrom" label="Datum od" type="date"  InputLabelProps={{ shrink: true }} helperText={touched.dateFrom ? errors.dateFrom : ""} error={touched.dateFrom && Boolean(errors.dateFrom)} onChange={handleChange} onBlur={handleBlur} value={values.dateFrom} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField id="dateTo" name="dateTo" label="Datum do" type="date"  InputLabelProps={{ shrink: true }} helperText={touched.dateTo ? errors.dateTo : ""} error={touched.dateTo && Boolean(errors.dateTo)} onChange={handleChange} onBlur={handleBlur} value={values.dateTo} fullWidth />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField id="name" name="name" label="Jméno a příjmení" helperText={touched.name ? errors.name : ""} error={touched.name && Boolean(errors.name)} onChange={handleChange} onBlur={handleBlur} value={values.name} fullWidth />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField id="street" name="street" label="Ulice a č.p." helperText={touched.street ? errors.street : ""} error={touched.street && Boolean(errors.street)} onChange={handleChange} onBlur={handleBlur} value={values.street} fullWidth />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField id="city" name="city" label="Město" helperText={touched.city ? errors.city : ""} error={touched.city && Boolean(errors.city)} onChange={handleChange} onBlur={handleBlur} value={values.city} fullWidth />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField id="phone" name="phone" label="Telefon" type="phone"  helperText={touched.phone ? errors.phone : ""} error={touched.phone && Boolean(errors.phone)} onChange={handleChange} onBlur={handleBlur} value={values.phone} fullWidth />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField id="zipcode" name="zipcode" label="PSČ" helperText={touched.zipcode ? errors.zipcode : ""} error={touched.zipcode && Boolean(errors.zipcode)} onChange={handleChange} onBlur={handleBlur} value={values.zipcode} fullWidth />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField id="email" name="email" label="Email" type="email"  helperText={touched.email ? errors.email : ""} error={touched.email && Boolean(errors.email)} onChange={handleChange} onBlur={handleBlur} value={values.email} fullWidth />
                </Grid>
                <Grid item xs={12} lg={12}>
                <br/>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField disabled id="recommendedPrice" name="recommendedPrice" label="Doporučená částka (na základě ceníku)" helperText={touched.recommendedPrice ? errors.recommendedPrice : ""} error={touched.recommendedPrice && Boolean(errors.recommendedPrice)} onChange={handleChange} onBlur={handleBlur} value={values.recommendedPrice} fullWidth  InputProps={{ endAdornment: <InputAdornment position="end">Kč </InputAdornment> }}/>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextField id="bail" name="bail" label="Dohodnutá kauce"  helperText={touched.bail ? errors.bail : ""} error={touched.total && Boolean(errors.bail)} onChange={handleChange} onBlur={handleBlur} value={values.bail} fullWidth InputProps={{ endAdornment: <InputAdornment position="end">Kč </InputAdornment> }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField id="price" name="price" label="Celková částka"  helperText={touched.price ? errors.price : ""} error={touched.price && Boolean(errors.price)} onChange={handleChange} onBlur={handleBlur} value={values.price} fullWidth InputProps={{ endAdornment: <InputAdornment position="start">Kč </InputAdornment> }} />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <FormControlLabel label="Kauce zaplacena" control={<Checkbox id="bailPaid" name="bailPaid" checked={values.bailPaid} onChange={handleChange} onBlur={handleBlur}  color="primary" className={values.bailPaid ? "checkbox-bail green" : "checkbox-bail"} />} />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <FormControlLabel label="Zaplacena celá částka" control={<Checkbox id="paid" name="paid" checked={values.paid} onChange={handleChange} onBlur={handleBlur}  color="primary" className={values.paid ? "checkbox-bail green" : "checkbox-bail"} />} />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField id="note" name="note" label="Poznámka"  helperText={touched.note ? errors.note : ""} error={touched.email && Boolean(errors.note)} onChange={handleChange} onBlur={handleBlur} value={values.note ? values.note : ""} fullWidth multiline />
                </Grid>

                <Grid item xs={12} className="buttons">
                  <MuiThemeProvider theme={themeDialogButtons}>
                    <Button variant="contained" color="primary" type="submit" disabled={this.props.loading}>
                      Uložit
                    </Button>
                    <Button variant="contained" color="secondary" type="button" disabled={this.props.loading} onClick={this.props.closed}>
                      Zavřít
                    </Button>
                  </MuiThemeProvider>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    prices: state.prices.prices,
    loading: state.prices.loading,
    dataArrived: state.prices.dataArrived,
    token: state.authentication.token,
    userId: state.authentication.userId,
    contact: state.contact,
    reservationSuccess: state.reservations.success,
    processingReservation: state.reservations.loadingItem
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPrices: () => dispatch(actions.getPrices()),
    onInsertReservation: (reservation, token) => dispatch(actions.insertReservation(reservation,token)),
    onReservationReset: () => dispatch(actions.resetReservation())
  };
};

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReservationDialog);
