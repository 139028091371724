import React from "react";
import StarRatingComponent from "react-star-rating-component";
import Star from "@material-ui/icons/Star";
import StarHalf from "@material-ui/icons/StarHalf";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";

import "./Review.css";


const Review = props => {
  return (
    <div className={"review"}>
      <div className="review-container">
        <div className="left">
          <Avatar
            aria-label="User"
            className={["avatar", props.color || ""].join(" ")}
          >
            {props.initials}
          </Avatar>
          <Hidden smDown>
            <StarRatingComponent
              name={"star" + props.id}
              value={props.rating}
              starCount={5}
              renderStarIcon={() => <Star />}
              renderStarIconHalf={() => <StarHalf />}
              editing={false}
              emptyStarColor="#ffb400"
            />
          </Hidden>
        </div>
        <div className="right">
          <div className="name">{props.name}</div>
          <div className="place">{props.place}</div>
          <Hidden smDown>
            <div className="content">{props.text}</div>
          </Hidden>
        </div>
      </div>
      <Hidden mdUp>
        <div className="sm-content">
          <StarRatingComponent
            name={"star" + props.id}
            value={props.rating}
            starCount={5}
            renderStarIcon={() => <Star />}
            renderStarIconHalf={() => <StarHalf />}
            editing={false}
            emptyStarColor="#ffb400"
          />
          <div className="content">{props.text}</div>
        </div>
      </Hidden>
    </div>
  );
};

export default Review;
