//export {default as img1}from "assets/img/gallery/9FE215BE-8CCB-4248-B05D-0FB4345E2F04.jpeg";
export {default as img2} from "assets/img/gallery/473A95AF-D9DA-4B9D-8170-3E6B13FF1F18.jpeg";
export {default as img3} from "assets/img/gallery/E1C12C8E-5E5D-4356-AFB9-F3D22EAF20EB.jpeg";
export {default as img4} from "assets/img/gallery/C70B03D9-8446-43D6-A568-582390E2C014.jpeg";
//vybaveni
export {default as img5} from "assets/img/gallery/82873EED-6A9A-490F-8971-D00A522A174B.jpeg";
export {default as img6} from "assets/img/gallery/44C72992-15AE-4059-A699-57EC9CB43010.jpeg";
export {default as img7} from "assets/img/gallery/08F13B43-8C3A-4246-96F1-D8C944E6C5F4.jpeg";
export {default as img8} from "assets/img/gallery/3DCBA151-367F-4494-9516-2FB5ABF538E1.jpeg";
export {default as img9} from "assets/img/gallery/C02FCB4E-0988-4A64-8C5A-09D80E358025.jpeg";
export {default as img10} from "assets/img/gallery/24951A53-32ED-4D54-AB12-D9611476AD8B.jpeg";
export {default as img11} from "assets/img/gallery/D5A746A9-7BCF-4512-84FF-496B38E01A19.jpeg";
export {default as img12} from "assets/img/gallery/D08D859A-A3A2-45C7-BBF5-7085B471F6CB.jpeg";
export {default as img13} from "assets/img/gallery/D929A227-DBE5-4E21-9D24-211263774070.jpeg";
export {default as img14} from "assets/img/gallery/D98981A1-EE76-40F1-9D76-A5E3F94795EE.jpeg";
export {default as img15} from "assets/img/gallery/DBF15C14-0570-427F-AF82-688DEDA061D9.jpeg";
export {default as img16} from "assets/img/gallery/CBDD5C4C-B3FA-41A9-A952-A14ABD66E536.jpeg";
export {default as img17} from "assets/img/gallery/FF758D70-E5F1-4CEC-9275-8BE41F4DFDC3.jpeg";
export {default as img18} from "assets/img/gallery/F8A324CC-971C-4811-B259-D5B324B74DCA.jpeg";
export {default as img19} from "assets/img/gallery/E104A882-CC95-4A2F-94E2-A74A197BFAFC.jpeg";
export {default as img20} from "assets/img/gallery/803CCE99-4AFA-4E5C-9581-705F53956B74.jpeg";
export {default as img21} from "assets/img/gallery/559E5C73-B556-4760-80E4-C8B4E592FAC1.jpeg";
export {default as img22} from "assets/img/gallery/FF949329-CA30-4BAD-A8B4-42E3D8837E59.jpeg";
export {default as img23} from "assets/img/gallery/959399E2-DEB1-405D-80E2-C7F830E6FD8F.jpeg";

//export {default as img1b} from "assets/img/gallery/thumbs/9FE215BE-8CCB-4248-B05D-0FB4345E2F04.jpeg";
export {default as img2b} from "assets/img/gallery/thumbs/473A95AF-D9DA-4B9D-8170-3E6B13FF1F18.jpeg";
export {default as img3b} from "assets/img/gallery/thumbs/E1C12C8E-5E5D-4356-AFB9-F3D22EAF20EB.jpeg";
export {default as img4b} from "assets/img/gallery/thumbs/C70B03D9-8446-43D6-A568-582390E2C014.jpeg";
//vybaveni
export {default as img5b} from "assets/img/gallery/thumbs/82873EED-6A9A-490F-8971-D00A522A174B.jpeg";
export {default as img6b} from "assets/img/gallery/thumbs/44C72992-15AE-4059-A699-57EC9CB43010.jpeg";
export {default as img7b} from "assets/img/gallery/thumbs/08F13B43-8C3A-4246-96F1-D8C944E6C5F4.jpeg";
export {default as img8b} from "assets/img/gallery/thumbs/3DCBA151-367F-4494-9516-2FB5ABF538E1.jpeg";
export {default as img9b} from "assets/img/gallery/thumbs/C02FCB4E-0988-4A64-8C5A-09D80E358025.jpeg";
export {default as img10b} from "assets/img/gallery/thumbs/24951A53-32ED-4D54-AB12-D9611476AD8B.jpeg";
export {default as img11b} from "assets/img/gallery/thumbs/D5A746A9-7BCF-4512-84FF-496B38E01A19.jpeg";
export {default as img12b} from "assets/img/gallery/thumbs/D08D859A-A3A2-45C7-BBF5-7085B471F6CB.jpeg";
export {default as img13b} from "assets/img/gallery/thumbs/D929A227-DBE5-4E21-9D24-211263774070.jpeg";
export {default as img14b} from "assets/img/gallery/thumbs/D98981A1-EE76-40F1-9D76-A5E3F94795EE.jpeg";
export {default as img15b} from "assets/img/gallery/thumbs/DBF15C14-0570-427F-AF82-688DEDA061D9.jpeg";
export {default as img16b} from "assets/img/gallery/thumbs/CBDD5C4C-B3FA-41A9-A952-A14ABD66E536.jpeg";
export {default as img17b} from "assets/img/gallery/thumbs/FF758D70-E5F1-4CEC-9275-8BE41F4DFDC3.jpeg";
export {default as img18b} from "assets/img/gallery/thumbs/F8A324CC-971C-4811-B259-D5B324B74DCA.jpeg";
export {default as img19b} from "assets/img/gallery/thumbs/E104A882-CC95-4A2F-94E2-A74A197BFAFC.jpeg";
export {default as img20b} from "assets/img/gallery/thumbs/803CCE99-4AFA-4E5C-9581-705F53956B74.jpeg";
export {default as img21b} from "assets/img/gallery/thumbs/559E5C73-B556-4760-80E4-C8B4E592FAC1.jpeg";
export {default as img22b} from "assets/img/gallery/thumbs/FF949329-CA30-4BAD-A8B4-42E3D8837E59.jpeg";
export {default as img23b} from "assets/img/gallery/thumbs/959399E2-DEB1-405D-80E2-C7F830E6FD8F.jpeg";

export {default as kitchen1} from "assets/img/1np/2C295DC6-63F6-4CF3-AF49-FBE11B094361.jpeg";
export {default as kitchen2} from "assets/img/1np/9D67D874-625B-4F22-8A96-4F05A6CC4CE5.jpeg";
export {default as kitchen3} from "assets/img/1np/44C72992-15AE-4059-A699-57EC9CB43010.jpeg";
export {default as kitchen4} from "assets/img/1np/82873EED-6A9A-490F-8971-D00A522A174B.jpeg";
export {default as kitchen5} from "assets/img/1np/D5A746A9-7BCF-4512-84FF-496B38E01A19.jpeg";
export {default as kitchen6} from "assets/img/1np/D929A227-DBE5-4E21-9D24-211263774070.jpeg";
export {default as kitchen7} from "assets/img/1np/FF758D70-E5F1-4CEC-9275-8BE41F4DFDC3.jpeg";
export {default as kitchen8} from "assets/img/1np/F5D04C5B-3A33-4D14-BEDC-672751F012C8.jpeg";
export {default as kitchen9} from "assets/img/1np/24951A53-32ED-4D54-AB12-D9611476AD8B.jpeg";
export {default as kitchen10} from "assets/img/1np/67718106-E4AA-491E-891D-5A1F4B4C0E5C.jpeg";


export {default as rooms1} from "assets/img/2np/3DCBA151-367F-4494-9516-2FB5ABF538E1.jpeg";
export {default as rooms2} from "assets/img/2np/C7F2942E-977B-40F9-97C2-0575A20DEF9B.jpeg";
export {default as rooms3} from "assets/img/2np/8F19B759-235D-41EB-8783-08608EF6CCA7.jpeg";
export {default as rooms4} from "assets/img/2np/0137AE6B-FBBC-48CF-82BB-85574FB36289.jpeg";
export {default as rooms5} from "assets/img/2np/7847A88A-EE27-46D0-9E2C-D107FA563D60.jpeg";
export {default as rooms6} from "assets/img/2np/B86D25C3-6729-4B79-8806-E7FC58CE884D.jpeg";
export {default as rooms7} from "assets/img/2np/C02FCB4E-0988-4A64-8C5A-09D80E358025.jpeg";
export {default as rooms8} from "assets/img/2np/C8FDD777-E3B4-4934-B014-66FD69B75026.jpeg";
export {default as rooms9} from "assets/img/2np/DBF15C14-0570-427F-AF82-688DEDA061D9.jpeg";
export {default as rooms10} from "assets/img/2np/A260986B-AC1F-4FB7-9C0F-2F87DD295388.jpeg";

export {default as attics1} from "assets/img/3np/B47568E4-38CB-4B20-A127-B4109AB6E852.jpeg";
export {default as attics2} from "assets/img/3np/9C633C3F-15C4-48F2-BE75-C2600A69B1C9.jpeg";
export {default as attics3} from "assets/img/3np/8DBE8CED-C484-449F-9D22-1C8B79D87E73.jpeg";
export {default as attics4} from "assets/img/3np/F431312E-BC11-4D79-B4F5-9924D3E2614F.jpeg";

//export {default as floor1} from "assets/img/0np/9FE215BE-8CCB-4248-B05D-0FB4345E2F04.jpeg";
export {default as floor2} from "assets/img/0np/473A95AF-D9DA-4B9D-8170-3E6B13FF1F18.jpeg";
export {default as floor3} from "assets/img/0np/E1C12C8E-5E5D-4356-AFB9-F3D22EAF20EB.jpeg";
export {default as floor4} from "assets/img/0np/C7259425-E99A-4192-9CF2-2E3E6DDE9944.jpeg";